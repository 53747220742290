import { Grid, IconButton, Menu, MenuItem, Snackbar, Theme, createStyles, makeStyles } from "@material-ui/core";
import { Delete as DeleteIcon, MoreVert as MoreVertIcon, Pause as PauseIcon, Search as SearchIcon } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Library from "../../../models/Library/Library";
import { KeyedArray } from "../../../models/keyed";
import { getLibrariesForOrganisation, renderLibraryListing } from "../../../services/http-library";
import ConfirmPauseLibraryDialog from "../../Dialogs/Group/ConfirmPauseLibraryDialog";
import ConfirmRemoveLibraryFromOrganisationDialog from "../../Dialogs/Group/ConfirmRemoveLibraryFromOrganisationDialog";
import CreateLibraryDialog from "../../Dialogs/Group/CreateLibraryDialog";
import DataTable from "../../Layout/DataTable";
import Loading from "../../Layout/Loading";
import SearchBar from "../../Layout/SearchBar";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    userActions: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    actions: {
      cursor: "pointer",
      color: theme.palette.primary.main,
    },
    icon: {
      margin: theme.spacing(1),
    },
    librariesTable: {
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    }
  })
);

export default function GroupLibraries() {
  const classes = useStyles();
  const { organisationId } = useParams<{ groupId?: string; organisationId: string }>();

  
  const [search, setSearch] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [headers, setHeaders] = useState<string[]>([]);
  const [data, setData] = useState<KeyedArray[]>([]);
  const [page, setPage] = useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [count, setCount] = useState<number>(0);
  const [gridLoading, setGridLoading] = useState(false);
  const [libraryMenuTarget, setLibraryMenuTarget] = useState<undefined | HTMLElement>(undefined);
  const [selectedLibrary, setSelectedLibrary] = useState<Library | undefined>(undefined);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [pauseDialogOpen, setPauseDialogOpen] = useState(false);
  const [snackbarOpen] = useState("");
  const [libraries, setLibraries] = useState<Library[]>();

  async function getLibraries() {
    const { libraries } = await getLibrariesForOrganisation(organisationId);
    setLibraries(libraries);
  }

  useEffect(() => {
    setGridLoading(true);
    getLibraries();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (libraries) {
      updateLibrariesData();
    }
    // eslint-disable-next-line
  }, [libraries, page, itemsPerPage]);

  function handleOnSearchTermChange(searchTerm: string) {
    return Promise.resolve(setSearchTerm(searchTerm));
  }

  function handleOnChangePage(event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) {
    setPage(page);
  }

  function handleOnChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setItemsPerPage(Number(event.target.value));
  }

  async function updateLibrariesData() {
    const startItem = page * itemsPerPage;
    const endItem = startItem + itemsPerPage;
    const keyedNestedLibrariesData = renderLibraryListing(libraries || [], searchTerm, startItem, endItem, getLibraryActions);

    setData(keyedNestedLibrariesData.keyedArr);
    setCount(keyedNestedLibrariesData.count);
    setHeaders(keyedNestedLibrariesData.headers);
    setGridLoading(false);
  }

  function getLibraryActions(library: Library) {
    return (
      <IconButton size="small" onClick={(event) => toggleLibraryActions(event, library)} className={classes.actions}>
        <MoreVertIcon />
      </IconButton>
    );
  }

  function toggleLibraryActions(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, library: Library) {
    setLibraryMenuTarget(event.currentTarget);
    setSelectedLibrary(library);
  }

  function closeMenus() {
    setLibraryMenuTarget(undefined);
  }

  function onCloseConfirmDialog(message: string) {
    closeMenus();
    getLibraries();
  }

  function handleSnackbarClose() {
    setDeleteDialogOpen(false);
    setPauseDialogOpen(false);
  }

  return (
    <>
      <Grid container className={classes.userActions} justify="space-between">
        {libraries && (
          <Grid item xs={8}>
            {search ? (
              <SearchBar
                placeholder="Search... (3 chars min)"
                searchTerm={searchTerm}
                onChange={handleOnSearchTermChange}
                onSubmit={updateLibrariesData}
              />
            ) : (
              <></>
            )}
          </Grid>
        )}
        <Grid item>
          <Grid container direction="row">
            {libraries && (
              <>
                <IconButton
                  id="search-libraries-button"
                  className={classes.icon}
                  color="primary"
                  onClick={() => setSearch(!search)}
                >
                  <SearchIcon />
                </IconButton>
                <CreateLibraryDialog organisationId={organisationId} reloadLibraries={getLibraries} />
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.librariesTable}>
        {gridLoading ? (
          <Loading />
        ) : (
          <DataTable
            rowsPerPageOptions={[10, 25]}
            headers={headers}
            data={data}
            page={page}
            itemsPerPage={itemsPerPage}
            onChangePage={handleOnChangePage}
            onChangeRowsPerPage={handleOnChangeRowsPerPage}
            count={count}
          />
        )}
      </div>
      {selectedLibrary && (
        <>
          <Menu
            id="group-user-actions-menu"
            anchorEl={libraryMenuTarget}
            onClose={closeMenus}
            open={Boolean(libraryMenuTarget)}
          >
            <MenuItem onClick={() => setPauseDialogOpen(true)}>
              <PauseIcon className={classes.icon} />
              {selectedLibrary.harvestingPaused ? "Unpause": "Pause"} library
            </MenuItem>
            <ConfirmPauseLibraryDialog
              open={pauseDialogOpen}
              library={selectedLibrary}
              onClose={() => setPauseDialogOpen(false)}
              onSuccess={() => onCloseConfirmDialog(`"${selectedLibrary.name}" library has been ${selectedLibrary.harvestingPaused ? "unpaused": "paused"}`)}
              organisationId={organisationId}
            />
            <MenuItem onClick={() => setDeleteDialogOpen(true)}>
              <DeleteIcon className={classes.icon} />
              Delete library from organisation
            </MenuItem>
          </Menu>
          <ConfirmRemoveLibraryFromOrganisationDialog
            open={deleteDialogOpen}
            library={selectedLibrary}
            onClose={() => setDeleteDialogOpen(false)}
            onSuccess={() => onCloseConfirmDialog(`"${selectedLibrary.name}" library deleted from organisation`)}
            organisationId={organisationId}
          />
        </>
      )}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={!!snackbarOpen}
        onClose={handleSnackbarClose}
        autoHideDuration={2500}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{snackbarOpen}</span>}
      />
    </>
  );
}
