import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

export const FontFamily: string = "Gordita, sans-serif";
export const ToolbarHeight: number = 50;
export const DrawerWidth: number = 240;
export const Blue: string = "#00A3E0";
export const Green: string = "#15ED9A";
export const Pink: string = "#DB3069";

const useStylesBase = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontFamily: FontFamily,
      display: "flex",
      height: "100vh",
      width: "100vw",
      overflow: "hidden",
      whiteSpace: "pre-line",
      background: theme.palette.background.default,
    },
    overrides: {
      "& a": {
        textDecoration: "unset",
        wordBreak: "break-word",
        cursor: "pointer",
        color: theme.palette.secondary.main,
        "&:visited": {
          color: theme.palette.secondary.main,
        },
      },
      "& .MuiButton-root": {
        fontWeight: "bold",
      },
      "& .MuiTableCell-root": {
        padding: 6,
      },
    },
  })
);

export default useStylesBase;
