import { Tooltip, Typography } from "@material-ui/core";
import { Pause as PauseIcon } from "@material-ui/icons";
import moment from "moment";
import React from 'react';
import DataTableEntries from "../models/DataTableEntries";
import { getKeyedString, KeyedArray } from "../models/keyed";
import Library from "../models/Library/Library";
import LibraryCreateRequest from "../models/Library/LibraryCreateRequest";
import { insightClient } from "./http";
import LibraryResponse from "../models/Library/LibraryResponse";

export async function getLibrariesForOrganisation(organisationId: string): Promise<LibraryResponse> {
  const { data } = await insightClient.get<LibraryResponse>(`/organisations/${organisationId}/libraries`);
  return data;
}

function dateTooltip(date: string): JSX.Element {
  return (
    <Tooltip title={moment(date).toString()}>
      <Typography variant="body2">{moment(date).fromNow()}</Typography>
    </Tooltip>
  );
}

export function renderLibraryListing(
    libraries: Library[],
    searchTerm: string,
    startItem: number,
    endItem: number,
    getLibraryActions: (library: Library) => JSX.Element
  ): DataTableEntries {
    const filteredLibraries = libraries.filter((x) =>
      !searchTerm ||
      (x.name.toLowerCase().includes(searchTerm.toLowerCase()) || 
      x.description?.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  
    const keyedArr = filteredLibraries.slice(startItem, endItem).map<KeyedArray>((library) => {
      return {
        key: library.id,
        entries: [
          getKeyedString(library.harvestingPaused ? <><PauseIcon style={{ verticalAlign: "bottom" }} />{library.name}</> : library.name, "name"),
          getKeyedString(library.description, "description"),
          getKeyedString(dateTooltip(library.created), "created"),
          getKeyedString(dateTooltip(library.updated), "updated"),
          getKeyedString(library.documentCount.toLocaleString(), "documentCount"),
          getKeyedString(getLibraryActions(library), "actions")
        ],
      };
    });
  
    return {
      keyedArr: keyedArr,
      count: filteredLibraries.length,
      headers: ["Name", "Description", "Created", "Updated", "Documents", ""],
    };
  }

export async function addLibraryToOrganisation(
  organisationId: string,
  library: LibraryCreateRequest
): Promise<void> {
  await insightClient.post(`/organisations/${organisationId}/libraries`, library);
}

export async function deleteLibraryFromOrganisation(
  organisationId: string,
  libraryId: string
): Promise<void> {
  await insightClient.delete(`/organisations/${organisationId}/libraries/${libraryId}`);
}

export async function pauseLibrary(
  organisationId: string,
  libraryId: string
): Promise<void> {
  await insightClient.put(`/organisations/${organisationId}/libraries/${libraryId}/schedules/pause`);
}

export async function unpauseLibrary(
  organisationId: string,
  libraryId: string
): Promise<void> {
  await insightClient.put(`/organisations/${organisationId}/libraries/${libraryId}/schedules/unpause`);
}
