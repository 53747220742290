import { Avatar, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import DataTableEntries from "../models/DataTableEntries";
import { getKeyedString, KeyedArray } from "../models/keyed";
import UserPatchData from "../models/User/UserPatchData";
import { accountClient, authClient } from "./http";
import { Membership, User } from "../models/User/User";
import { getEmail, getLastLogin, getLoginsCount, getName, getPicture, getUserId } from "../helpers/user";
import { UserList, UserListItem } from "../models/User/UserList";

const patchReplace = (name: string, value: string | boolean) => ({ path: `/${name}`, value, op: "replace" });

export async function updateUser(userId: string, { name, password, blocked }: UserPatchData) {
  const body = [
    ...(name !== undefined ? [patchReplace("name", name)] : []),
    ...(password !== undefined ? [patchReplace("password", password)] : []),
    ...(blocked !== undefined ? [patchReplace("blocked", blocked)] : []),
  ];

  return await accountClient.patch<User>(`/users/${encodeURI(userId)}/`, body);
}

export async function searchUsers(q: string, page: number, size: number): Promise<UserList> {
  const params = { page: page + 1, q, size };
  const { data } = await accountClient.get<UserList>("/users/", { params });

  return data;
}

export async function createUser(email: string, name: string, organisationId: string): Promise<User> {
  const { data: newUser } = await accountClient.post<User>(`/organisations/${encodeURI(organisationId)}/users/`, { email, name });
  return newUser;
}

export async function getUser(userId: string): Promise<User> {
  const { data } = await accountClient.get<User>(`/users/${encodeURI(userId)}/`);
  return data;
}

export async function getUserMembership(userId: string): Promise<Membership> {
  const { data } = await accountClient.get<Membership>(`/users/${encodeURI(userId)}/membership/`);
  return data;
}

export async function deleteUser(userId: string) {
  await accountClient.delete(`/users/${encodeURI(userId)}/`);
}

export async function deleteUserFromGroup(organisationId: string, groupId: string, userId: string) {
  await accountClient.delete(`/organisations/${encodeURI(organisationId)}/groups/${encodeURI(groupId)}/users/${encodeURI(userId)}/`);
}

export async function resetPassword(email: string) {
  return await authClient.post("/dbconnections/change_password", {
    email,
    connection: "Username-Password-Authentication",
  });
}

export async function getUserListing(
  q: string,
  page: number,
  itemsPerPage: number,
  getUserActions: <T extends UserListItem>(userEntry: T) => JSX.Element
): Promise<DataTableEntries> {
  try {
    const userResponse = await searchUsers(q, page, itemsPerPage);
    const keyedArr = getUserListingActions(userResponse, getUserActions);

    return {
      keyedArr: keyedArr,
      count: userResponse.total_items,
      headers: getUserHeaders(),
    };
  } catch (err) {
    console.error(err);
    return {
      keyedArr: [],
      count: 0,
      headers: getUserHeaders(),
    };
  }
}

export async function blockUser(userId: string): Promise<void> {
  await updateUser(userId, { blocked: true });
}

export async function unblockUser(userId: string): Promise<void> {
  await updateUser(userId, { blocked: false });
}

export async function searchOrganisationUsers(organisationId: string, q?: string, page?: number, size?: number): Promise<UserList> {
  const params = {
    q,
    size,
    page: page !== undefined ? page + 1 : undefined,
  };
  const { data } = await accountClient.get<UserList>(`/organisations/${organisationId}/users/`, { params });

  return data;
}

export async function getOrganisationUsers(
  organisationId: string,
  page: number,
  size: number,
  getUserActions: (user: UserListItem) => JSX.Element,
  q?: string
): Promise<DataTableEntries> {
  
  const userResponse = await searchOrganisationUsers(organisationId, q, page, size);

  return {
    keyedArr: getUserListingActions(userResponse, getUserActions),
    count: userResponse.total_items,
    headers: getUserHeaders(),
  };
}

export function getUserListingActions(
  data: UserList,
  getUserActions: <T extends UserListItem>(userEntry: T) => JSX.Element
) {
  return data.users.map<KeyedArray>((user) => {
    const timeSinceLastLogin = getLastLogin(user) ? moment(getLastLogin(user)).fromNow() : "Never";
    const userLink = (
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Avatar
          style={{
            height: 30,
            width: 30,
            marginRight: 10,
          }}
          src={getPicture(user)}
        />
        <Typography color="secondary">
          <Link color="inherit" to={`/manage/users/${getUserId(user)}`}>
            {getName(user).trim() || getEmail(user)}
          </Link>
        </Typography>
        {user.blocked && (
          <Typography
            style={{
              marginLeft: 10,
              paddingLeft: 4,
              paddingRight: 4,
              paddingTop: 2,
              paddingBottom: 2,
              color: "#EF5350",
              border: "1px solid #EF5350",
              borderRadius: 2,
            }}
          >
            BLOCKED
          </Typography>
        )}
      </div>
    );
    return {
      key: getUserId(user),
      entries: [
        getKeyedString(userLink, "name"),
        getKeyedString(getEmail(user), "email"),
        getKeyedString(timeSinceLastLogin, "lastLogin"),
        getKeyedString(getLoginsCount(user).toString(), "loginsCount"),
        getKeyedString(getUserActions(user), "actions"),
      ],
    };
  });
}

function getUserHeaders() {
  return ["Name", "Email", "Last Login", "Logins", ""];
}

