import { accountClient } from "./http";
import { SubscriptionList } from "../models/Subscription/SubscriptionList";
import { Subscription } from "../models/Subscription/Subscription";

export async function getSubscriptionsForOrganisation(organisationId: string): Promise<Subscription[]> {
  const { data: { subscriptions = [] } } = await accountClient.get<SubscriptionList>(`/organisations/${encodeURI(organisationId)}/subscriptions/`);
  return subscriptions;
}

export async function addSubscriptionForOrganisation(
  organisationId: string,
  applicationId: string,
  expiry: Date,
): Promise<void> {
  await accountClient.put(`/organisations/${encodeURI(organisationId)}/subscriptions/${encodeURI(applicationId)}/`, { expiry });
}

export async function deleteSubscriptionForOrganisation(
  organisationId: string,
  applicationId: string,
): Promise<void> {
  await accountClient.delete(`/organisations/${encodeURI(organisationId)}/subscriptions/${encodeURI(applicationId)}/`);
}
