import { ArrowBack as ArrowBackIcon, Block as BlockIcon, Delete as DeleteIcon, Email as EmailIcon, MoreVert as MoreVertIcon } from "@material-ui/icons";
import { makeStyles, createStyles, Theme, Avatar, Chip, IconButton, Grid, Menu, MenuItem, Snackbar, Tab, Tabs, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { getUser, blockUser, unblockUser, resetPassword, getUserMembership } from "../../../services/http-user";
import ConfirmRemoveUserFromGroupDialog from "../../Dialogs/Group/ConfirmRemoveUserFromGroupDialog";
import AddUserToGroupsDialog from "../../Dialogs/Group/AddUserToGroupsDialog";
import SendConfirmationEmailDialog from "../../Dialogs/SendConfirmationEmailDialog";
import { useAuth0 } from "../../Auth0/AuthWrapper";
import { Membership, User } from "../../../models/User/User";
import { canResetPassword, getEmail, getOrganisation, getGroups as getUserGroups, getIdentities, getLastLogin, getLastPasswordReset, getLoginsCount, getName, getOrganisationId, getPicture, getUserId, hasOrganisation, isUserBlocked, isUserVerified } from "../../../helpers/user";
import { Group } from "../../../models/Group/Group";
import { getGroups } from "../../../services/http-group";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    canvasStyle: {
      height: "100%",
      margin: theme.spacing(6),
      padding: theme.spacing(3),
    },
    connection: {
      marginRight: theme.spacing(1)
    },
    avatar: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(2),
      height: theme.spacing(7),
      width: theme.spacing(7)
    },
    blockedText: {
      marginLeft: theme.spacing(2),
      paddingLeft: 4,
      paddingRight: 4,
      paddingTop: 2,
      paddingBottom: 2,
      color: "#EF5350",
      border: "1px solid #EF5350",
      borderRadius: 2,
    },
    textContainer: {
      display: "flex",
      width: "100%",
      padding: theme.spacing(4),
    },
    infoText: {
      display: "block",
    },
    infoTextLabel: {
      fontWeight: "bold"
    },
    infoTitleTextContainer: {
      marginRight: theme.spacing(2),
    },
    tableHeader: {
      padding: theme.spacing(3),
    },
    groupContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    groupTable: {
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    listClass: {
      backgroundColor: theme.palette.background.paper,
    },
    actions: {
      color: theme.palette.primary.main,
    },
    addUserToGroupsContainer: {
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: theme.spacing(1),
    },
    avatarTitle: {
      display: "flex",
      alignItems: "center",
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    tabs: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(2),
      borderBottom: "1px solid #e9e9e9"
    },
    code: {
      backgroundColor: "#e0e0e0",
      display: "block",
      padding: theme.spacing(2),
      width: "100%",
      color: "rgba(0, 0, 0, 0.87)"
    }
  })
);

export default function UserInfo() {
  const classes = useStyles();
  const { isSuperAdmin } = useAuth0();
  const history = useHistory();
  const { userId } = useParams<{ userId?: string }>();

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [selectedUser, setSelectedUser] = useState<User>();
  const [userMembership, setUserMembership] = useState<Membership>();
  const [tab, setTab] = useState<number>(0);
  const [groupMenuTarget, setGroupMenuTarget] = useState<undefined | HTMLElement>(undefined);
  const [userMenuTarget, setUserMenuTarget] = useState<undefined | HTMLElement>(undefined);
  const [groups, setGroups] = useState<Group[] | null>(null);
  const [selectedGroupEntry, setSelectedGroupEntry] = useState<Group | undefined>(undefined);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [resetPasswordDialogOpen, setResetPasswordDialogOpen] = useState<boolean>(false);
  const [deleteSnackbarOpen, setDeleteSnackbarOpen] = useState<boolean>(false);
  const [resetPasswordSnackbarOpen, setResetPasswordSnackbarOpen] = useState<boolean>(false);

  async function loadUser(userIdentifier?: string) {
    if (userIdentifier) {
      try {
        const [user, membership] = await Promise.all([getUser(userIdentifier), getUserMembership(userIdentifier) ]);
        setSelectedUser(user);
        setUserMembership(membership);
        setErrorMessage("");
      } catch (e) {
        setErrorMessage("Unable to retrieve this user.");
      }
    }
  }

  async function fetchGroups(organisationId: string) {
    const { groups } = await getGroups(organisationId);
    setGroups(groups);
  }

  function getGroupActions(groupEntry: Group) {
    return (
      <IconButton size="small" onClick={(event) => toggleGroupActions(event, groupEntry)} className={classes.actions}>
        <MoreVertIcon />
      </IconButton>
    );
  }

  function toggleGroupActions(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, groupEntry: Group) {
    setGroupMenuTarget(event.currentTarget);
    setSelectedGroupEntry(groupEntry);
    setUserMenuTarget(undefined);
  }

  function closeMenus() {
    setGroupMenuTarget(undefined);
    setUserMenuTarget(undefined);
  }

  function handleTabChange(event: React.ChangeEvent<{}>, value: number) {
    setTab(value);
  }

  function onCloseConfirmDialog(options: { reload: boolean }) {
    setDeleteDialogOpen(false);
    setGroupMenuTarget(undefined);
    if (options.reload) {
      reloadGroups();
      setDeleteSnackbarOpen(true);
    }
  }

  function onCloseConfirmResetPasswordDialog(options: { reload: boolean }) {
    setResetPasswordDialogOpen(false);
    setUserMenuTarget(undefined);
  }

  function handleSnackbarClose() {
    setDeleteDialogOpen(false);
    setResetPasswordSnackbarOpen(false);
  }

  function reloadGroups() {
    setTimeout(() => {
      loadUser(userId);
    }, 1000);
  }

  async function sendResetPasswordEmail(user: User) {
    await resetPassword(getEmail(user));
    setResetPasswordSnackbarOpen(true);
    setUserMenuTarget(undefined);
  }

  async function handleBlockUser(user: User) {
    setUserMenuTarget(undefined);
    const fn = isUserBlocked(user) ? unblockUser : blockUser;
    await fn(getUserId(user));
    loadUser(userId);
  }

  function openUserActionsMenu(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setUserMenuTarget(event.currentTarget);
    setGroupMenuTarget(undefined);
  }

  function generatePasswordResetActionLabel(user: User): string {
    if (isUserVerified(user)) {
      return "Send password reset email";
    } else {
      return "Send welcome email";
    }
  }

  function generatePasswordResetSuccessLabel(user: User): string {
    if (isUserVerified(user)) {
      return `Reset password email sent to ${getEmail(user)}`;
    } else {
      return `Welcome email sent to ${getEmail(user)}`;
    }
  } 

  useEffect(() => {
    loadUser(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userMembership) {
      fetchGroups(getOrganisationId(userMembership) as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userMembership]);

  return selectedUser && userMembership ? (
    <>
      <Grid container className={classes.tableHeader} direction="row" justify="space-between">
        <Grid item className={classes.avatarTitle}>
          <IconButton onClick={() => history.goBack()}>
            <ArrowBackIcon />
          </IconButton>
          <Avatar className={classes.avatar} src={getPicture(selectedUser)} />
          <Grid container direction="column">
            <Grid item>
              <Grid container direction="row">
                <Typography variant="h6">{getName(selectedUser)}</Typography>
                {isUserBlocked(selectedUser) && <Typography className={classes.blockedText}>BLOCKED</Typography>}
              </Grid>
            </Grid>
            <Grid item style={{ marginTop: 2 }}>
              <Chip label={getUserId(selectedUser)} size="small" />
            </Grid>
            <Grid item style={{ marginTop: 6 }}>
              {hasOrganisation(userMembership) ?
                (
                  <>
                    Member of{" "}
                    <Link color="inherit" to={`/manage/organisations/${getOrganisationId(userMembership)}`}>
                      <Chip label={getOrganisation(userMembership)?.name} size="small" style={{ cursor: "pointer" }} />
                    </Link>
                  </>
                ) :
                (
                  <Typography className={classes.blockedText} style={{ display: "inline" }}>NO ORGANISATION</Typography>
                )}
            </Grid>
          </Grid>

        </Grid>
        <Grid item>
          <IconButton size="small" className={classes.actions} onClick={openUserActionsMenu}>
            <MoreVertIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Menu
        id="user-actions-menu"
        anchorEl={userMenuTarget}
        onClose={() => closeMenus()}
        open={Boolean(userMenuTarget)}
      >
        {!isUserBlocked(selectedUser) && <MenuItem disabled={!canResetPassword(selectedUser)} onClick={() => setResetPasswordDialogOpen(true)}>
          <EmailIcon className={classes.icon} />
          {generatePasswordResetActionLabel(selectedUser)}
        </MenuItem>}
        <MenuItem onClick={() => handleBlockUser(selectedUser)}>
          <BlockIcon className={classes.icon} />
          {selectedUser.blocked ? "Unblock User" : "Block User"}
        </MenuItem>
      </Menu>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={resetPasswordSnackbarOpen}
        onClose={handleSnackbarClose}
        autoHideDuration={2500}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{generatePasswordResetSuccessLabel(selectedUser)}</span>}
      />
      <SendConfirmationEmailDialog
        open={resetPasswordDialogOpen}
        user={selectedUser as User}
        onClose={onCloseConfirmResetPasswordDialog}
        sendResetPasswordEmail={sendResetPasswordEmail}
      />
      <Tabs value={tab} onChange={handleTabChange} className={classes.tabs}>
        <Tab label="Information" />
        <Tab label="Groups" />
      </Tabs>
      {tab === 0 &&
        (errorMessage || (
          <>
            <div className={classes.textContainer}>
              <div className={classes.infoTitleTextContainer}>
                <Typography className={classes.infoTextLabel} variant="body1">
                  Name:
                </Typography>
                <Typography className={classes.infoTextLabel} variant="body1">
                  Email:
                </Typography>
                <Typography className={classes.infoTextLabel} variant="body1">
                  Email Verified:
                </Typography>
                <Typography className={classes.infoTextLabel} variant="body1">
                  Last Login:
                </Typography>
                <Typography className={classes.infoTextLabel} variant="body1">
                  Login Count:
                </Typography>
                <Typography className={classes.infoTextLabel} variant="body1">
                  Last Password Reset:
                </Typography>
                <Typography className={classes.infoTextLabel} variant="body1">
                  Identities:
                </Typography>
              </div>
              <div>
                <Typography className={classes.infoText} variant="body1">
                  {getName(selectedUser)}
                </Typography>
                <Typography className={classes.infoText} variant="body1">
                  {getEmail(selectedUser)}
                </Typography>
                <Typography className={classes.infoText} variant="body1">
                  {isUserVerified(selectedUser) ? "Yes" : "No"}
                </Typography>
                <Tooltip title={getLastLogin(selectedUser) || "Never"}>
                  <Typography className={classes.infoText} variant="body1">
                    {getLastLogin(selectedUser) ? moment(getLastLogin(selectedUser)).fromNow() : "Never"}
                  </Typography>
                </Tooltip>
                <Typography className={classes.infoText} variant="body1">
                  {getLoginsCount(selectedUser)}
                </Typography>
                <Tooltip title={getLastPasswordReset(selectedUser) || "Never"}>
                  <Typography className={classes.infoText} variant="body1">
                    {getLastPasswordReset(selectedUser) ? moment(getLastPasswordReset(selectedUser)).fromNow() : "Never"}
                  </Typography>
                </Tooltip>
                <Grid container direction="row" className={classes.infoText}>
                  {getIdentities(selectedUser).map(({ connection }) => (
                    <Chip label={connection} key={connection} size="small" className={classes.connection} />
                  ))}
                </Grid>
              </div>
            </div>
            <Grid container direction="column" className={classes.textContainer}>
              <Grid item>
                <Typography className={classes.infoTextLabel} variant="body1">User Metadata</Typography>
              </Grid>
            </Grid>
            <Grid container direction="column" className={classes.textContainer}>
              <Grid item>
                <Typography className={classes.infoTextLabel} variant="body1">Membership</Typography>
              </Grid>
              <Grid item>
                <pre>
                  <code className={classes.code}>{JSON.stringify(userMembership, null, 4)}</code>
                </pre>
              </Grid>
            </Grid>
            <Grid container direction="column" className={classes.textContainer}>
              <Grid item>
                <Typography className={classes.infoTextLabel} variant="body1">Identities</Typography>
              </Grid>
              <Grid item>
                <pre>
                  <code className={classes.code}>{JSON.stringify(getIdentities(selectedUser), null, 4)}</code>
                </pre>
              </Grid>
            </Grid>
          </>
        ))}
      {tab === 1 && (
        <>
          <div className={classes.addUserToGroupsContainer}>
            {selectedUser && <AddUserToGroupsDialog organisationId={getOrganisationId(userMembership) as string} reloadGroups={reloadGroups} userId={getUserId(selectedUser)} />}
          </div>
          <div className={classes.groupTable}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {groups?.filter(g => getUserGroups(userMembership).map(g => g.id).includes(g.group_id)).map((group) => (
                  <TableRow key={group.group_id}>
                    <TableCell component="th" scope="row">
                      <Typography color="secondary">
                        <Link color="inherit" to={`/manage/organisations/${getOrganisationId(userMembership)}/groups/${group.group_id}`}>
                          {group.name}
                        </Link>
                      </Typography>
                    </TableCell>
                    <TableCell>{group.description}</TableCell>
                    <TableCell>
                      {isSuperAdmin && getGroupActions(group)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </>
      )}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={deleteSnackbarOpen}
        onClose={handleSnackbarClose}
        autoHideDuration={2500}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">User removed from group</span>}
      />
      {selectedGroupEntry && (
        <>
          <Menu
            id="group-user-actions-menu"
            anchorEl={groupMenuTarget}
            onClose={closeMenus}
            open={Boolean(groupMenuTarget)}
          >
            <MenuItem disabled={!hasOrganisation(userMembership)} onClick={() => setDeleteDialogOpen(true)}>
              <DeleteIcon className={classes.icon} />
              Remove user from group
            </MenuItem>
          </Menu>
          <ConfirmRemoveUserFromGroupDialog
            open={deleteDialogOpen}
            organisationId={getOrganisationId(userMembership) as string}
            userId={getUserId(selectedUser)}
            email={getEmail(selectedUser)}
            name={getName(selectedUser)}
            onClose={onCloseConfirmDialog}
            groupName={selectedGroupEntry.name}
            groupId={selectedGroupEntry.group_id}
          />
        </>
      )}
    </>
  ) : (
    <></>
  );
}
