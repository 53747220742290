import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Link, useLocation } from "react-router-dom";
import Business from "@material-ui/icons/Business";
import Person from "@material-ui/icons/Person";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import { DrawerWidth } from "../../styles/styles-base";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backRow: {
      padding: theme.spacing(2),
      display: "flex",
      alignItems: "center",
    },
    backIcon: {
      cursor: "pointer",
    },
    drawer: {
      width: DrawerWidth,
      flexShrink: 0,
      zIndex: theme.zIndex.drawer - 200,
    },
    drawerPaper: {
      width: DrawerWidth,
      background: "#eaebed",
      borderRight: 0,
    },
    link: {
      textDecoration: "none",
    },
    toolbar: theme.mixins.toolbar,
  })
);

export default function NavMenu() {
  const classes = useStyles();
  const { pathname } = useLocation();
  const [path, setPath] = useState<string>("");

  useEffect(() => {
    if (pathname && pathname.split("/")[2].includes("users")) {
      setPath("users");
    } else {
      setPath("organisations");
    }
  }, [pathname]);

  const usersLabel = (
    <>
      <ListItemIcon>
        <Person />
      </ListItemIcon>
      <ListItemText>
        <Typography color="textPrimary">Users</Typography>
      </ListItemText>
    </>
  );
  const organisationsLabel = (
    <>
      <ListItemIcon>
        <Business />
      </ListItemIcon>
      <ListItemText>
        <Typography color="textPrimary">Organisations</Typography>
      </ListItemText>
    </>
  );

  return (
    <Drawer className={classes.drawer} variant="permanent" classes={{ paper: classes.drawerPaper }}>
      <div className={classes.toolbar} />
      <List disablePadding>
        <Link to="/manage/users" className={classes.link}>
          <ListItem id="nav-users-link" button selected={path === "users"}>
            {usersLabel}
          </ListItem>
        </Link>
        <Link to="/manage/organisations" className={classes.link}>
          <ListItem id="nav-organisations-link" button selected={path === "organisations"}>
            {organisationsLabel}
          </ListItem>
        </Link>
      </List>
    </Drawer>
  );
}
