import { useQuery } from "react-query";
import { accountClient } from "../services/http";

function useBillingAPI(organisationId: string, endpoint: string) {
    return `${process.env.REACT_APP_API_V2_URL}/organisations/${organisationId}/${endpoint}`;
}

export default function useOrganisationSubscription(organisationId: string) {
    const api = useBillingAPI(organisationId, "billing/subscriptions");
    return useQuery(["organisation-subscription"], async () => {
        const { data } = await accountClient.get(api);
        return data || null;
    }, { staleTime: -1 });
}