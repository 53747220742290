import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { Route, Switch } from "react-router-dom";
import theme from "../styles/theme";
import "./App.css";
import { useAuth0 } from "./Auth0/AuthWrapper";
import PrivateRoute from "./Auth0/PrivateRoute";
import Main from "./Layout/Main";
import NavMenu from "./Layout/NavMenu";
import Group from "./Pages/Group/Group";
import Home from "./Pages/Home";
import Organisation from "./Pages/Organisation/Organisation";
import Profile from "./Pages/Profile";
import Skeleton from "./Pages/Skeleton";
import UserInfo from "./Pages/User/UserInfo";
import Users from "./Pages/User/Users";
import Welcome from "./Pages/Welcome";
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

export default function App() {
  const { loading } = useAuth0();

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
      <CssBaseline />

        <Main>
          <Switch>
            <Route
              path="/welcome"
              exact
              render={() => {
                return <Welcome />;
              }}
            />

            {!loading ? (
              <>           
                <PrivateRoute
                  path="/"
                  exact
                  render={() => {
                    return <Home />;
                  }}
                />
                <PrivateRoute
                  path="/account"
                  exact
                  render={() => {
                    return <Profile />;
                  }}
                />
                <PrivateRoute
                  path="/manage/users"
                  exact
                  render={() => {
                    return (
                      <>
                        <NavMenu />
                        <Users selectedTab="users" />
                      </>
                    );
                  }}
                />
                <PrivateRoute
                  path="/manage/users/:userId"
                  exact
                  render={() => {
                    return (
                      <>
                        <NavMenu />
                        <UserInfo />
                      </>
                    );
                  }}
                />
                <PrivateRoute
                  path="/manage/organisations"
                  exact
                  render={() => {
                    return (
                      <>
                        <NavMenu />
                        <Users selectedTab="organisations" />
                      </>
                    );
                  }}
                />
                <PrivateRoute
                  path="/manage/organisations/:organisationId"
                  exact
                  render={() => {
                    return (
                      <>
                        <NavMenu />
                        <Organisation />
                      </>
                    );
                  }}
                />
                <PrivateRoute
                  path="/manage/organisations/:organisationId/groups"
                  exact
                  render={() => {
                    return (
                      <>
                        <NavMenu />
                        <Users selectedTab="groups" />
                      </>
                    );
                  }}
                />
                <PrivateRoute
                  path="/manage/organisations/:organisationId/groups/:groupId"
                  exact
                  render={() => {
                    return (
                      <>
                        <NavMenu />
                        <Group />
                      </>
                    );
                  }}
                />
              </>
            ) : (
              <Skeleton />
            )}
          </Switch>
        </Main>
        </QueryClientProvider>
    </ThemeProvider>
  );
}
