import React from "react";
import DILogo from "../assets/deepinsight-logo-dark-trim.png";
import DRLogo from "../assets/deepresearch-logo-dark.png";

export interface Product {
  id: string;
  name: string;
  logo: React.FunctionComponent;
  comingSoon: boolean;
  features: string[];
  link: string;
  appId: string;
  active: boolean;
  disabled?: boolean;
}

const products: Product[] = [
  {
    active: true,
    appId: process.env.REACT_APP_ANALYSE_ID as string,
    comingSoon: false,
    features: ["More insights, less effort", "Instant, tailored insights from over 100 million patents, papers & news sources"],
    id: "dvanalyse",
    link: process.env.REACT_APP_ANALYSE_URL as string,
    logo: () => <img src={DILogo} height={30} alt="DeepInsight logo" />,
    name: "DeepInsight",
  },
  {
    active: true,
    appId: process.env.REACT_APP_DEEP_RESEARCH_ID as string,
    comingSoon: false,
    features: ["Find everything faster", "Empower them to search up to 400 internal & external sources, with one click"],
    id: "deepresearch",
    link: process.env.REACT_APP_DEEP_RESEARCH_URL as string,
    logo: () => <img src={DRLogo} height={30} alt="DeepResearch logo" />,
    name: "DeepResearch",
  },
];

export default products;
