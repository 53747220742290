import { createStyles, Theme, makeStyles, Button, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar } from "@material-ui/core";
import { GroupAdd as GroupAddIcon } from "@material-ui/icons";
import { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import SelectOptions from "../../../models/SelectOptions";
import { addUserToGroups, getGroups } from "../../../services/http-group";
import { Group } from "../../../models/Group/Group";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorMessage: {
      color: "red",
      marginTop: theme.spacing(8),
    },
    dialogContent: {
      paddingBottom: theme.spacing(25),
    },
    icon: {
      margin: theme.spacing(1),
    },
    paper: {
      overflow: "visible",
    },
  })
);

interface AddUserToGroupsDialogProps {
  organisationId: string;
  reloadGroups: () => any;
  userId: string;
}

export default function AddUserToGroupsDialog({ organisationId, userId, reloadGroups }: AddUserToGroupsDialogProps) {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [groups, setGroups] = useState<Group[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<SelectOptions[]>([]);

  function handleClose() {
    setOpen(false);
    setSelectedGroups([]);
  }

  async function handleAddUserToGroups() {
    setLoading(true);

    try {
      await addUserToGroups(organisationId, userId, selectedGroups.map((x) => x.value));
    } catch (err) {
      const error = err as AxiosError<{ detail?: string }>;
      setLoading(false);
      setErrorMessage(error.response?.data.detail || "Error adding user to groups");
      return;
    }

    setLoading(false);
    setSnackbarOpen(true);
    handleClose();
    setTimeout(() => {
      reloadGroups();
    }, 1000);
  }

  async function handleSearchGroups(searchTerm: string) {
    if (searchTerm.length < 3) {
      searchTerm = "";
    }

    setErrorMessage("");
    return groups
      .filter(g => !searchTerm || (g.name.toLowerCase().includes(searchTerm.toLowerCase())))
      .map<SelectOptions>((g) => ({
        value: g.group_id,
        label: g.name,
      }));
  }

  function handleOnSelectChange(selectedOptions: any) {
    setSelectedGroups(selectedOptions);
  }

  async function fetchGroups() {
    const { groups } = await getGroups(organisationId);
    setGroups(groups);
  }

  useEffect(() => {
    fetchGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="add-user-to-groups-dialog">
      <IconButton color="primary" className={classes.icon} onClick={() => setOpen(true)}>
        <GroupAddIcon />
      </IconButton>
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title-add-user-to-groups"
        classes={{ paper: classes.paper }}
      >
        <DialogTitle id="form-dialog-title-add-user-to-groups">Add user to groups</DialogTitle>
        <DialogContent
          classes={{
            root: classes.paper,
          }}
        >
          <AsyncSelect
            id="search-groups"
            isMulti
            autoFocus
            loadOptions={handleSearchGroups}
            value={selectedGroups}
            closeMenuOnScroll={false}
            closeMenuOnSelect={true}
            onChange={handleOnSelectChange}
            placeholder="Search... (3 chars min)"
          />
          {errorMessage && <DialogContentText className={classes.errorMessage}>{errorMessage}</DialogContentText>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button disabled={loading} onClick={handleAddUserToGroups} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        autoHideDuration={2500}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">User successfully added to groups</span>}
      />
    </div>
  );
}
