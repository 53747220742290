import React, { FunctionComponent, useEffect } from "react";
import { Route, RouteProps } from "react-router-dom";
import { useAuth0 } from "./AuthWrapper";

interface PrivateRouteProps {
  component?: React.FunctionComponent;
  path: string;
}

const PrivateRoute: FunctionComponent<PrivateRouteProps & RouteProps> = ({ component: Component, path, ...rest }) => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: path },
      });
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect, path]);

  const render = (props: any) => (isAuthenticated === true && Component ? <Component {...props} /> : undefined);

  return <Route path={path} render={render} {...rest} />;
};

export default PrivateRoute;