import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Container, Grid, TextField, Typography, Snackbar, CircularProgress, Accordion as ExpansionPanel, AccordionSummary as ExpansionPanelSummary, AccordionDetails as ExpansionPanelDetails, List, ListItem, ListItemIcon, ListItemText, createStyles, makeStyles, Theme, useTheme } from "@material-ui/core";
import { CheckCircleOutline as CheckCircleOutlineIcon, ExpandMore as ExpandMoreIcon, CancelOutlined as CancelOutlinedIcon } from "@material-ui/icons";
import { updateUser } from "../../services/http-user";
import UserPatchData from "../../models/User/UserPatchData";
import { useAuth0 } from "../Auth0/AuthWrapper";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: 20,
      color: theme.palette.primary.main,
      margin: "0",
    },
    textInput: {},
    expansionBase: {
      background: "none",
      width: "100%",
    },
    expansionContent: {
      paddingLeft: 0,
      paddingRight: 0,
      justifyContent: "flex-start",
      "& .MuiExpansionPanelSummary-content": {
        flexGrow: 0,
      },
    },
    passwordListItem: {
      paddingTop: 4,
      paddingBottom: 4,
      "& .MuiListItemIcon-root": {
        minWidth: theme.spacing(4),
      },
    },
    listItemText: {
      "& .MuiTypography-root": {
        fontSize: 13,
        color: "#233656",
      },
    },
    snack: {
      background: "rgb(50, 179, 136)",
    },
  })
);

export default function ProfilePage() {
  const classes = useStyles();
  const { user } = useAuth0();
  const theme = useTheme();
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [hasError, setError] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [snackOpen, setSnackOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);
  const variant = "outlined";

  useEffect(() => {
    if (user) {
      setName(user.name);
      setEmail(user.email);
    }
  }, [user]);

  useEffect(() => {
    if (password.length > 0 && expanded) {
      setError(getErrors(passwordErrors).length > 0);
    } else {
      setError(getErrors(["nameLength"]).length > 0);
    }
    setShowConfirm(false);
    // eslint-disable-next-line
  }, [name, password, confirmPassword, expanded]);

  const validation: {[key: string]: {fn: () => boolean, message: string}} = {
    nameLength: {
      fn: () => name.length > 0,
      message: "Please enter a name",
    },
    minLength: {
      fn: () => password.length >= 8,
      message: "must be at least 8 characters",
    },
    lowerCase: {
      fn: () => /[a-z]/g.test(password),
      message: "must contain a lower case letter (a-z)",
    },
    upperCase: {
      fn: () => /[A-Z]/g.test(password),
      message: "must contain an upper case letter (A-Z)",
    },
    number: {
      fn: () => /[0-9]/g.test(password),
      message: "must contain a number",
    },
    special: {
      fn: () => /[!@#$%^&*(),.?":{}|<>]/g.test(password),
      message: "must contain a special character (!@#$ etc)",
    },
    equalPassword: {
      fn: () => password.length > 0 && password === confirmPassword,
      message: "passwords must match",
    },
  };

  function getErrors(validationList: string[]) {
    return validationList.filter((v) => !validation[v].fn()).map((v) => validation[v].message);
  }

  function hasErrors() {
    return getErrors(Object.keys(validation)).length > 0
  }

  async function saveUser(userId: string, data: UserPatchData) {
    try {
      setLoading(true);

      await updateUser(userId, data);

      setLoading(false);
      setSnackOpen(true);
      setPassword("")
      setConfirmPassword("")
      setExpanded(false)
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  }

  function confirmSave() {
    const equalPass = expanded === true ? password === confirmPassword : true;
    if (!hasError && equalPass) {
      const data: UserPatchData = { name };

      if (expanded && password.length > 0) {
        data.password = password;
      }

      saveUser(user.sub, data);
    } else {
      setShowConfirm(true);
    }
  }

  function handleSnackClose() {
    setSnackOpen(false);
  }

  const passwordErrors = ["minLength", "lowerCase", "upperCase", "number", "special", "equalPassword"];

  function NameRow() {
    return (
      <Grid container item spacing={2} direction="row">
        <Grid item xs={12} md={6}>
          <TextField
            id="name-entry"
            error={hasError && getErrors(["nameLength"]).length > 0}
            helperText={hasError && getErrors(["nameLength"])[0]}
            className={classes.textInput}
            value={name}
            autoComplete="new-password"
            label="Name"
            variant={variant}
            onChange={(event: any) => setName(event.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="email-entry"
            className={classes.textInput}
            value={email}
            label="Email"
            variant={variant}
            disabled
            fullWidth
          />
        </Grid>
      </Grid>
    );
  }

  function PasswordComplexityList() {
    return (
      <Grid item>
        <List>
          {Object.keys(validation)
            .filter((item) => passwordErrors.indexOf(item) > -1)
            .map((item) => (
              <ListItem key={item} className={classes.passwordListItem} disableGutters>
                <ListItemIcon>
                  {
                    validation[item].fn() ? <CheckCircleOutlineIcon style={{
                      color: "rgb(50, 179, 136)",
                      transition: "color .2s"}} /> :
                  <CancelOutlinedIcon style={{transition: "color .2s", color: 'red'}} />
                  }

                </ListItemIcon>
                <ListItemText className={classes.listItemText} primary={validation[item].message} />
              </ListItem>
            ))}
        </List>
      </Grid>
    );
  }

  function PasswordRow() {
    return (
      <Grid item container xs={12}>
        <ExpansionPanel
          onChange={(event, isExpanded) => setExpanded(isExpanded)}
          className={classes.expansionBase}
          square
          expanded={expanded}
          elevation={0}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.expansionContent}>
            <Typography variant="body2">Update password</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.expansionContent}>
            <Grid container item direction="column">
              <Grid container item spacing={2} direction="row">
                <Grid item xs={12} md={6}>
                  <TextField
                    id="newpassword-entry"
                    type="password"
                    className={classes.textInput}
                    autoComplete="new-password"
                    label="New password"
                    value={password}
                    variant={variant}
                    onChange={(event: any) => setPassword(event.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="confirm-entry"
                    type="password"
                    className={classes.textInput}
                    label="Confirm password"
                    value={confirmPassword}
                    variant={variant}
                    onChange={(event: any) => setConfirmPassword(event.target.value)}
                    fullWidth
                  />
                </Grid>
                {PasswordComplexityList()}
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Grid>
    );
  }

  return (
    <Container style={{ marginTop: theme.spacing(4) }} maxWidth="md">
      <Grid container alignItems="center" justify="center" direction="column" spacing={4} xs={12}>
        <Grid item xs="auto">
          <h2 className={classes.title}>My Account</h2>
        </Grid>
        <Grid item xs="auto">
          <Link color="secondary" to="/">
            Back to launcher
          </Link>
        </Grid>
        <Grid container item direction="column" xs="auto">
          {NameRow()}
          {PasswordRow()}
        </Grid>

        {getErrors(["equalPassword"]).length > 0 && showConfirm && getErrors(passwordErrors).length === 0 && (
          <Grid container item xs={12}>
            <Typography variant="body2" color="error">
              {getErrors(["equalPassword"])[0]}
            </Typography>
          </Grid>
        )}
        <Grid container item xs={12}>
          <Button
            endIcon={loading && <CircularProgress size={16} />}
            disabled={loading || hasErrors()}
            color="secondary"
            variant="contained"
            onClick={() => confirmSave()}
          >
            <>{loading ? "Saving" : "Save"}</>
          </Button>
        </Grid>
      </Grid>

      <Snackbar
        ContentProps={{
          classes: {
            root: classes.snack,
          },
        }}
        onClose={handleSnackClose}
        open={snackOpen}
        autoHideDuration={5000}
        message="Account updated"
      />
    </Container>
  );
}
