import * as Sentry from "@sentry/browser";
import { createBrowserHistory } from "history";
import React from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import App from "./components/App";
import { Auth0Provider } from "./components/Auth0/AuthWrapper";
import * as serviceWorker from "./serviceWorker";

const history = createBrowserHistory();

const { REACT_APP_SENTRY_DSN, NODE_ENV } = process.env;
if (REACT_APP_SENTRY_DSN) {
  Sentry.init({ dsn: REACT_APP_SENTRY_DSN, environment: NODE_ENV });
}

ReactDOM.render(
  <Router history={history}>
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
    redirect_uri={process.env.REACT_APP_AUTH0_REDIRECT_URI as string}
    audience={process.env.REACT_APP_AUTH0_AUDIENCE as string}
  >
    <App />
  </Auth0Provider>
  </Router>,
  document.getElementById("root")
);

serviceWorker.unregister();
