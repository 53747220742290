import { createStyles, Theme, makeStyles, Button, IconButton, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, InputLabel, MenuItem, Select, Snackbar } from "@material-ui/core";
import { PersonAdd as PersonAddIcon } from "@material-ui/icons";
import { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import { Organisation } from "../../../models/Organisation/Organisation";
import { createUser } from "../../../services/http-user";
import { getOrganisations } from "../../../services/http-organisation";
import useValidation from "../../../hooks/useValidation";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap" as any,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    selectOffset: {
      marginBottom: theme.spacing(4),
    },
    errorMessage: {
      color: "red",
      marginTop: theme.spacing(8),
    },
    icon: {
      margin: theme.spacing(1),
    },
  })
);

interface CreateUserDialogProps {
  reloadUsers: () => void;
}

export default function CreateUserDialog(props: CreateUserDialogProps) {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [organisations, setOrganisations] = useState<Organisation[]>([]);
  const [selectedOrgId, setSelectedOrgId] = useState("");
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const { reloadUsers } = props;
  const { valid: emailValid } = useValidation(["email"], [], [email]);

  useEffect(() => {
    requestOrganisations();
  }, []);

  function handleClose() {
    setOpen(false);
    setSelectedOrgId("");
    setName("");
    setEmail("");
  }

  async function requestOrganisations() {
    const { organisations } = await getOrganisations();
    setOrganisations(organisations);
  }

  function handleGroupChange(
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    child: React.ReactNode
  ) {
    setSelectedOrgId(event.target.value as string);
  }

  function handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    setName(event.target.value);
  }

  function handleEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
    setEmail(event.target.value);
  }

  async function createNewUser() {
    const selectedOrg = (organisations || []).find((x) => x.organisation_id === selectedOrgId);

    if (!selectedOrg || !name || !email) {
      const fields = [];
      if (!selectedOrg) {
        fields.push("Organisation");
      }
      if (!name) {
        fields.push("Name");
      }
      if (!email) {
        fields.push("Email Address");
      }
      setErrorMessage(`All fields are required. Please fill: ${fields.join(", ")}`);
      return;
    }

    setLoading(true);
    setErrorMessage("");

    try {
      await createUser(email, name, selectedOrg.organisation_id);
      reloadUsers();
    } catch (err) {
      const error = err as AxiosError<{ detail?: string; }>;
      setErrorMessage(error.response?.data.detail || "Error creating a user");
      setLoading(false);
      return;
    }

    setLoading(false);
    setSnackbarOpen(true);
    handleClose();
  }

  return (
    <div>
      <IconButton id="create-user-button" className={classes.icon} color="primary" onClick={() => setOpen(true)}>
        <PersonAddIcon />
      </IconButton>
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        id="create-user-dialog"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title-create-user"
      >
        <DialogTitle id="form-dialog-title-create-user">Create a user</DialogTitle>
        <DialogContent>
          <TextField
            margin="normal"
            autoFocus
            id="create-user-name"
            label="Name"
            type="text"
            fullWidth
            onChange={handleNameChange}
            value={name}
          />
          <TextField
            margin="normal"
            id="create-user-email"
            label="Email Address"
            type="email"
            className={classes.selectOffset}
            fullWidth
            onChange={handleEmailChange}
            value={email}
            error={!emailValid}
            helperText={!emailValid ? "Must be valid email address" : ""}
          />
          <InputLabel htmlFor="organisations">Organisation</InputLabel>
          <Select fullWidth disabled={!organisations.length} value={selectedOrgId} id="create-user-org" onChange={handleGroupChange}>
            <MenuItem value={""} />
            {organisations.map(({ organisation_id: id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
          {errorMessage && <DialogContentText className={classes.errorMessage}>{errorMessage}</DialogContentText>}
        </DialogContent>
        <DialogActions>
          <Button id="create-user-cancel" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button id="create-user-create" disabled={loading || !emailValid} onClick={createNewUser} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        autoHideDuration={2500}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">User successfully created</span>}
      />
    </div>
  );
}
