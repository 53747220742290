import { accountClient } from "./http";
import { RoleList } from "../models/Role/RoleList";
import { Role } from "../models/Role/Role";

export async function getRolesForOrganisation(organisationId: string): Promise<Role[]> {
  const { data: { roles = [] }} = await accountClient.get<RoleList>(`/organisations/${encodeURI(organisationId)}/roles/`);
  return roles;
}

export async function getApplicationRoles(applicationId: string): Promise<Role[]> {
  const { data: { roles = [] }} = await accountClient.get<RoleList>(`/applications/${encodeURI(applicationId)}/roles`);
  return roles;
}

export async function addRoleForOrganisation(organisationId: string, roleId: string): Promise<void> {
  await accountClient.put(`/organisations/${encodeURI(organisationId)}/roles/${encodeURI(roleId)}/`);
}

export async function removeRoleForOrganisation(organisationId: string, roleId: string): Promise<void> {
  await accountClient.delete(`/organisations/${encodeURI(organisationId)}/roles/${encodeURI(roleId)}/`);
}
