export interface KeyedArray {
  key: string;
  entries: KeyedString[];
}

export interface KeyedString {
  key: string;
  entry: string | JSX.Element;
}

export function getKeyedString(stringtoKey: string | JSX.Element, key: string): KeyedString {
  return {
    entry: stringtoKey,
    key: key,
  };
}
