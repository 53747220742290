import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/amplyfi-logo.png";
import { useAuth0 } from "../Auth0/AuthWrapper";
import AppBar from "./AppBar";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    grow: {
      flexGrow: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    menuButton: {
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    menuContainer: {
      padding: theme.spacing(3),
    },
    menuFooter: {
      borderTop: "1px solid #e6e6e6",
      padding: theme.spacing(2),
    },
    menuFooterLink: {
      "&:hover": {
        background: "#f7f8f8"
      },
      textDecoration: "none",
      color: "#5f6368",
      padding: theme.spacing(0.5, 1),
      fontSize: theme.typography.fontSize
    },
    headerAvatar: {
      marginLeft: "auto",
    },
    avatar: {
      marginRight: theme.spacing(2),
    },
    logo: {
      height: "1.5em",
      marginRight: theme.spacing(8),
    },
  })
);

export default function TopAppBar() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<undefined | HTMLElement>(undefined);
  const { user, logout } = useAuth0();

  function handleMenuOpen(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleMenuClose() {
    setAnchorEl(undefined);
  }

  const homeLink = <Link to="/">
      <img alt="logo" className={classes.logo} src={logo} />
    </Link>

  const toolbarButtons = user
    ? [
      <Button key="header-avatar" id="header-avatar" onClick={handleMenuOpen} color="inherit">
        <Avatar alt="user-avatar" src={user.picture} />
      </Button>,
      <Popover
        key="appbar-user-actions-menu"
        id="appbar-user-actions-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={undefined}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleMenuClose}
        open={Boolean(anchorEl)}
      >
        <Grid container direction="row" className={classes.menuContainer} wrap="nowrap">
          <Grid item>
            <Avatar alt="user-menu-avator" id="user-menu-avatar" className={classes.avatar} src={user!.picture} />
          </Grid>
          <Grid item>
            <Grid container direction="column">
              <Typography id="user-menu-nickname" variant="h6" color="inherit">
                {user!.nickname}
              </Typography>
              <Typography id="user-menu-email" variant="body1" color="inherit">
                {user!.email}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justify="space-around" className={classes.menuButton}>
          <Button variant="contained" color="secondary" component={Link} to="/account">
            My Account
          </Button>
          <Button
            color="primary"
            onClick={() => logout({ logoutParams: { returnTo: process.env.REACT_APP_AUTH0_REDIRECT_URI }})}
          >
            Logout
          </Button>
        </Grid>
        <Grid container justify="center" direction="row" className={classes.menuFooter}>
          <Grid item >
            <a href="https://amplyfi.com/support" className={classes.menuFooterLink}>
              Help
            </a>
          </Grid>
        </Grid>
      </Popover>,
    ]
    : [];

  return <AppBar logo={homeLink} searchElement={<></>} buttons={toolbarButtons} />;
}
