import React from "react";
import Library from "../../../models/Library/Library";
import { deleteLibraryFromOrganisation } from "../../../services/http-library";
import ConfirmDialog from "../ConfirmDialog";

interface ConfirmRemoveLibraryFromOrganisationDialogProps {
  library: Library;
  onClose: () => void;
  onSuccess: () => void;
  open: boolean;
  organisationId: string;
}

export default function ConfirmRemoveLibraryFromOrganisationDialog({
  library: { description, id, name },
  onClose,
  onSuccess,
  open,
  organisationId
}: ConfirmRemoveLibraryFromOrganisationDialogProps) {
  async function handleAction() {
    await deleteLibraryFromOrganisation(organisationId, id);
    onSuccess();
    onClose();
  }

  return (
    <ConfirmDialog
      onClose={onClose}
      open={open}
      action={handleAction}
      dialogContentText={`Delete "${name}" (${description}) from organisation?`}
    />
  );
}
