import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import DeleteForever from "@material-ui/icons/DeleteForever";
import DataTable from "../../Layout/DataTable";
import SearchBar from "../../Layout/SearchBar";
import { KeyedArray } from "../../../models/keyed";
import Loading from "../../Layout/Loading";
import CreateUserDialog from "../../Dialogs/User/CreateUserDialog";
import { getUserListing } from "../../../services/http-user";
import { getKeyedOrganisationsArray, getOrganisations } from "../../../services/http-organisation";
import CreateOrganisationDialog from "../../Dialogs/User/CreateOrganisationDialog";
import ConfirmDeleteUserDialog from "../../Dialogs/User/ConfirmDeleteUserDialog";
import { useAuth0 } from "../../Auth0/AuthWrapper";
import { UserListItem } from "../../../models/User/UserList";
import { Organisation } from "../../../models/Organisation/Organisation";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    canvasStyle: {
      height: "100%",
      margin: theme.spacing(6),
    },
    grid: {
      margin: theme.spacing(3),
    },
    tableHeader: {
      paddingTop: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    noAccess: {
      textAlign: "center" as "center",
      padding: theme.spacing(6),
    },
    actions: {
      cursor: "pointer",
      color: theme.palette.primary.main,
      padding: 0,
    },
    icon: {
      margin: theme.spacing(1),
    },
    title: {
      padding: theme.spacing(1),
    },
  })
);

interface UsersProps {
  selectedTab: string;
}

export default function Users(props: UsersProps) {
  const classes = useStyles();
  const { isSuperAdmin, isDelegatedAdmin } = useAuth0();
  const { selectedTab } = props;
  const [data, setData] = useState<KeyedArray[]>([]);
  const [headers, setHeaders] = useState<string[]>([]);
  const [page, setPage] = useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [count, setCount] = useState<number>(0);
  const [search, setSearch] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [currentSearchTerm, setCurrentSearchTerm] = useState<string>("");
  const [organisations, setOrganisations] = useState<Organisation[]>([]);
  const [gridLoading, setGridLoading] = useState<boolean>(false);
  const [menuTarget, setMenuTarget] = useState<undefined | HTMLElement>(undefined);
  const [selectedUser, setSelectedUser] = useState<UserListItem | undefined>(undefined);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [organisationErrorMessage, setOrganisationErrorMessage] = useState<string>("");
  const [userErrorMessage, setUserErrorMessage] = useState<string>("");

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [page, itemsPerPage, searchTerm]);

  useEffect(() => {
    setPage(0);
    setSearchTerm("");
    setCurrentSearchTerm("");
    // eslint-disable-next-line
  }, [selectedTab]);

  async function fetchData() {
    setGridLoading(true);
    if (selectedTab === "organisations") {
      fetchOrganisations(true);
    } else if (selectedTab === "users") {
      fetchUsers();
      fetchOrganisations(false);
    }
  }

  async function fetchUsers() {
    const userResponse = await searchUsers();
    if (userResponse) {
      setHeaders(userResponse.headers);
      setData(userResponse.keyedArr);
      setCount(userResponse.count);
      setGridLoading(false);
    }
  }

  async function searchUsers() {
    setUserErrorMessage("");
    try {
      return await getUserListing(searchTerm, page, itemsPerPage, getUserActions);
    } catch (err) {
      console.error(err);
      setUserErrorMessage("Could not retrieve user data");
    }
  }

  function handleOnChangePage(event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) {
    setPage(page);
  }

  function handleOnChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setItemsPerPage(Number(event.target.value));
  }

  const renderForAdmin = gridLoading ? (
    <Loading />
  ) : (
    <DataTable
      rowsPerPageOptions={[10, 25, 50]}
      headers={headers}
      data={data}
      page={page}
      itemsPerPage={itemsPerPage}
      onChangePage={handleOnChangePage}
      onChangeRowsPerPage={handleOnChangeRowsPerPage}
      count={count}
    />
  );

  const renderError = <p className={classes.noAccess}>You do not have access to the delegated admin dashboard</p>;

  async function fetchOrganisations(force: boolean = false) {
    if (organisations.length === 0 || force) {
      try {
        const { organisations } = await getOrganisations();
        setOrganisations(organisations);
        setOrganisationErrorMessage("");
        if (selectedTab === "organisations") {
          setSelectedOrganisations(organisations);
        }
      } catch (err) {
        setOrganisationErrorMessage("Could not retrieve organsiation data");
      }
    }
  }

  function forceReloadOrganisations() {
    fetchOrganisations(true);
  }

  function setSelectedOrganisations(groups: Organisation[]) {
    const startItem = page * itemsPerPage;
    const endItem = startItem + itemsPerPage;
    const keyedOrganisationData = getKeyedOrganisationsArray(groups, searchTerm, startItem, endItem);
    setData(keyedOrganisationData.keyedArr);
    setCount(keyedOrganisationData.count);
    setHeaders(keyedOrganisationData.headers);
    setGridLoading(false);
  }

  function getUserActions(userEntry: UserListItem) {
    return (
      <IconButton size="small" onClick={(event) => toggleActions(event, userEntry)} className={classes.actions}>
        <MoreVertIcon />
      </IconButton>
    );
  }

  function toggleActions(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, userEntry: UserListItem) {
    setMenuTarget(event.currentTarget);
    setSelectedUser(userEntry);
  }

  function onSearch() {
    setSearchTerm(currentSearchTerm);
    setPage(0);
  }

  function onSearchTermChange(searchTerm: string) {
    return Promise.resolve(setCurrentSearchTerm(searchTerm));
  }

  function onCloseConfirmDialog(options: { reload: boolean }) {
    setDeleteDialogOpen(false);
    setMenuTarget(undefined);
    if (options.reload) {
      reloadUsers();
      setSnackbarOpen(true);
    }
  }

  function reloadUsers() {
    // give auth0 a chance to reindex
    setTimeout(() => fetchUsers(), 1000);
  }

  function renderTitle(tab: string) {
    switch(tab) {
      case "users":
        return "Users";
      case "organisations":
        return "Organisations";
    }
  }

  return (
    <>
      {isSuperAdmin || isDelegatedAdmin ? (
        <>
          <div>
            <Grid container className={classes.tableHeader} direction="row" justify="space-between" alignItems="center">
              <Grid item xs={8}>
                {search ? (
                  <SearchBar
                    searchTerm={currentSearchTerm}
                    onChange={onSearchTermChange}
                    placeholder="Search... (3 chars min)"
                    onSubmit={onSearch}
                  />
                ) : (
                  <Typography className={classes.title} variant="h6">
                    {renderTitle(selectedTab)}
                  </Typography>
                )}
              </Grid>
              <Grid item>
                <Grid container direction="row">
                  <IconButton
                    id="search-user-button"
                    className={classes.icon}
                    color="primary"
                    onClick={() => setSearch(!search)}
                  >
                    <SearchIcon />
                  </IconButton>
                  {selectedTab === "users" ? (
                    <CreateUserDialog reloadUsers={reloadUsers} />
                  ) : (
                    <CreateOrganisationDialog reloadOrganisations={forceReloadOrganisations} />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid container direction="row">
              <Grid className={classes.grid} item xs={12}>
                {selectedTab === "organisations" && (organisationErrorMessage ? organisationErrorMessage : renderForAdmin)}
                {selectedTab === "users" && (userErrorMessage ? userErrorMessage : renderForAdmin)}
              </Grid>
            </Grid>
          </div>
          <Menu
            id="user-actions-menu"
            anchorEl={menuTarget}
            onClose={() => setMenuTarget(undefined)}
            open={Boolean(menuTarget)}
          >
            <MenuItem onClick={() => setDeleteDialogOpen(true)}>
              <DeleteForever className={classes.icon} />
              Delete User
            </MenuItem>
          </Menu>
          {selectedUser && (
            <ConfirmDeleteUserDialog
              open={deleteDialogOpen}
              user={selectedUser}
              onClose={onCloseConfirmDialog}
            />
          )}
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={snackbarOpen}
            onClose={() => setSnackbarOpen(false)}
            autoHideDuration={2500}
            ContentProps={{
              "aria-describedby": "user-delete-message",
            }}
            message={<span id="user-delete-message">User successfully deleted</span>}
          />
        </>
      ) : (
        renderError
      )}
    </>
  );
}
