import React from "react";
import ConfirmDialog from "./ConfirmDialog";
import { getEmail, getName } from "../../helpers/user";
import { User } from "../../models/User/User";

interface SendConfirmationEmailDialogProps {
  open: boolean;
  user: User;
  onClose: (options: { reload: boolean }) => void;
  sendResetPasswordEmail: (user: User) => Promise<void>;
}

export default function SendConfirmationEmailDialog(props: SendConfirmationEmailDialogProps) {
  const { open, user, onClose, sendResetPasswordEmail } = props;

  function handleClose(options: { reload: boolean }) {
    onClose(options);
  }

  async function handleSendResetPasswordEmail() {
    await sendResetPasswordEmail(user);
  }

  return (
    user && (
      <ConfirmDialog
        onClose={handleClose}
        open={open}
        action={handleSendResetPasswordEmail}
        dialogContentText={`Send password set up email to ${getName(user)} (${getEmail(user)})?`}
      />
    )
  );
}
