import React, { useEffect, useState } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import logo from "../../assets/amplyfi-logo.png";
import { click } from "../../helpers/analytics";
import { resetPassword } from "../../services/http-user";
import { ToolbarHeight } from "../../styles/styles-base";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      color: "white",
    },
    dense: {
      marginTop: 16,
    },
    menu: {
      width: 200,
    },
    wrapper: {
      backgroundColor: "#012a3a",
      position: "absolute",
      width: "100%",
      height: `calc(100% - ${ToolbarHeight}px)`,
      textAlign: "center",
      color: "white",
      marginTop: -theme.spacing(4),
    },
    button: {
      backgroundColor: "#3e6cff",
      color: "white",
      border: "0px solid",
      borderRadius: "5px",
      width: "500px",
      height: "50px",
      fontSize: "15px",
      marginTop: "20px",
      cursor: "pointer",
    },
    emailInput: {
      width: "500px",
      height: "50px",
      fontSize: "15px",
      padding: "12px 20px",
      margin: "8px 0",
      display: "inline-block",
      border: "1px solid #ccc",
      borderRadius: "4px",
      boxSizing: "border-box",
    },
    logo: {
      maxWidth: "300px",
      marginTop: "10%",
    },
    title: {
      color: "white",
      marginTop: "50px",
    },
    subtile: {
      color: "white",
      marginBottom: "50px",
    },
  })
);

export default function Welcome() {
  const classes = useStyles();
  const [email, setEmail] = useState<string>("");
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    click("Load", "PageOpen", "opened page");
  }, []);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setEmail(event.target.value);
    setEmailSent(false);
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (email) {
      click("SignUp", "click", "email");
      resetPassword(email)
        .then((response) => {
          setEmailSent(true);
          click("Confirmation", "click", JSON.stringify(response));
        })
        .catch((err: any) => {
          setEmailSent(false);
          setError(true);
          click("ERROR", "error", JSON.stringify(err));
        });
    }
  }

  return (
    <div className={classes.wrapper}>
      <img className={classes.logo} src={logo} alt="amplyfi logo" />
      <Typography variant="h6" className={classes.title} gutterBottom>
        Welcome to AMPLYFI
      </Typography>
      <Typography variant="h6" className={classes.subtile} gutterBottom>
        Please enter your email address to get started.
      </Typography>

      <form onSubmit={handleSubmit}>
        <input
          id="outlined-email-input"
          type="email"
          name="email"
          value={email}
          onChange={handleChange}
          autoComplete="email"
          placeholder="Email"
          className={classes.emailInput}
        />
        <br />
        <input className={classes.button} type="submit" value="Confirm my email address" />
      </form>
      {emailSent ? (
        <Typography variant="h6" className={classes.title} gutterBottom>
          Thank you. <br /> We have sent a confirmation email to <i>{email}</i>. <br />
          Please check your inbox and follow the instructions to confirm this email address.
        </Typography>
      ) : undefined}
      {error ? (
        <Typography variant="h6" className={classes.title} gutterBottom>
          Something went wrong please contact support@amplyfi.com
        </Typography>
      ) : undefined}
    </div>
  );
}
