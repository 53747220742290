import ReactGA from "react-ga4";
import { AuthUser, getGroups, getOrganisation } from "./authUser";

export function createConnection(user: AuthUser): void {
  const trackingId: string | undefined = process.env.REACT_APP_GA4_MEASUREMENT_ID;

  if (trackingId) {
    const { sub: userId } = user;
    const { id: organisationId, name: organisationName } = getOrganisation(user);

    ReactGA.gtag("set", "user_properties", {
      userId,
      organisationId,
      organisationName,
      groups: getGroups(user).map((group) => group.name).join(","),
    });

    ReactGA.initialize([{
      trackingId,
      gaOptions: { userId },
    }]);
  }
}

export function userAction(category: string, action: string, label: string): void {
  ReactGA.event({
    action,
    category,
    label,
  });
}

export function click(category: string, action: string, label: string): void {
  ReactGA.event({
    action,
    category,
    label,
    value: 1,
  });
}

