import React from "react";
import ConfirmDialog from "../ConfirmDialog";
import { getEmail, getName, getUserId } from "../../../helpers/user";
import { deleteUser } from "../../../services/http-user";
import { UserListItem } from "../../../models/User/UserList";

interface ConfirmDeleteUserDialogProps {
  open: boolean;
  user: UserListItem;
  onClose: (options: { reload: boolean }) => void;
}

export default function ConfirmDeleteUserDialog(props: ConfirmDeleteUserDialogProps) {
  const { open, user, onClose } = props;

  async function handleAction() {
    await deleteUser(getUserId(user));
  }

  return user ? (
    <ConfirmDialog
      onClose={onClose}
      open={open}
      action={handleAction}
      dialogContentText={`Permanently delete ${getName(user)} (${getEmail(user)}) ?`}
    />
  ) : (
    <></>
  );
}
