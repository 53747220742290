import React, { useEffect, useState } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Grid, Menu, MenuItem, Snackbar, IconButton } from "@material-ui/core";
import { Delete, Search as SearchIcon, MoreVert as MoreVertIcon } from "@material-ui/icons";
import DataTable from "../../Layout/DataTable";
import SearchBar from "../../Layout/SearchBar";
import { KeyedArray } from "../../../models/keyed";
import Loading from "../../Layout/Loading";
import { useAuth0 } from "../../Auth0/AuthWrapper";
import { useParams } from "react-router-dom";
import { UserListItem } from "../../../models/User/UserList";
import AddUsersToOrganisationDialog from "../../Dialogs/Group/AddUsersToOrganisationDialog";
import { getOrganisationUsers } from "../../../services/http-user";
import ConfirmDeleteUserDialog from "../../Dialogs/User/ConfirmDeleteUserDialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    userActions: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    actions: {
      cursor: "pointer",
      color: theme.palette.primary.main,
    },
    icon: {
      margin: theme.spacing(1),
    },
    userTable: {
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    exportLoading: {
      marginLeft: theme.spacing(2.75),
      marginRight: theme.spacing(2.75),
      marginTop: theme.spacing(2.75),
    },
  })
);

export default function OrganisationMembers() {
  const classes = useStyles();
  const { organisationId } = useParams<{ organisationId: string }>();

  const [search, setSearch] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [data, setData] = useState<KeyedArray[]>([]);
  const [headers, setHeaders] = useState<string[]>([]);
  const [page, setPage] = useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [count, setCount] = useState<number>(0);
  const [gridLoading, setGridLoading] = useState<boolean>(false);
  const [menuTarget, setMenuTarget] = useState<undefined | HTMLElement>(undefined);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [selectedUser, setSelectedUser] = useState<UserListItem | undefined>(undefined);
  const { isSuperAdmin } = useAuth0();

  useEffect(() => {
    reloadUsers();
    // eslint-disable-next-line
  }, [organisationId]);

  useEffect(() => {
    searchUsers();
    // eslint-disable-next-line
  }, [page, itemsPerPage]);

  function handleSearchTermChange(searchTerm: string) {
    return Promise.resolve(setSearchTerm(searchTerm));
  }

  function handleOnChangePage(event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) {
    setPage(page);
  }

  function handleOnChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setItemsPerPage(Number(event.target.value));
  }

  async function fetchData() {
    setGridLoading(true);
    const response = await getOrganisationUsers(organisationId, page, itemsPerPage, getUserActions);
    setHeaders(response.headers);
    setCount(response.count);
    setData(response.keyedArr);
    setGridLoading(false);
  }

  function getUserActions(userEntry: UserListItem) {
    return isSuperAdmin ? (
      <IconButton size="small" onClick={(event) => toggleActions(event, userEntry)} className={classes.actions}>
        <MoreVertIcon />
      </IconButton>
    ) : (
      <></>
    );
  }

  function toggleActions(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, userEntry: UserListItem) {
    setMenuTarget(event.currentTarget);
    setSelectedUser(userEntry);
  }

  async function onSearchSubmit() {
    setPage(0);
    searchUsers();
  }

  async function searchUsers() {
    setGridLoading(true);

    if (searchTerm === "") {
      fetchData();
    } else {
      const groupMemberResponse = await getOrganisationUsers(
        organisationId,
        page,
        itemsPerPage,
        getUserActions,
        searchTerm,
      );

      setCount(groupMemberResponse.count);
      setHeaders(groupMemberResponse.headers);
      setData(groupMemberResponse.keyedArr);
      setGridLoading(false);
    }
  }

  async function reloadUsers() {
    fetchData();
  }

  function onCloseConfirmDialog(options: { reload: boolean }) {
    setDeleteDialogOpen(false);
    setMenuTarget(undefined);
    if (options.reload) {
      setTimeout(() => {
        searchUsers();
      }, 1000);

      setSnackbarOpen(true);
      setSnackbarMessage("User successfully deleted");
    }
  }

  return (
    <>
      <Grid container className={classes.userActions} justify="space-between">
        <Grid item xs={8}>
          {search ? (
            <SearchBar
              placeholder="Search... (3 chars min)"
              searchTerm={searchTerm}
              onChange={handleSearchTermChange}
              onSubmit={onSearchSubmit}
            />
          ) : (
            <></>
          )}
        </Grid>
        <Grid item>
          <Grid container direction="row">
            <IconButton
              id="search-users-button"
              className={classes.icon}
              color="primary"
              onClick={() => setSearch(!search)}
            >
              <SearchIcon />
            </IconButton>
            <AddUsersToOrganisationDialog organisationId={organisationId} reloadUsersAndGroups={reloadUsers} />
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.userTable}>
        {gridLoading ? (
          <Loading />
        ) : (
          <DataTable
            rowsPerPageOptions={[10, 25]}
            headers={headers}
            data={data}
            page={page}
            itemsPerPage={itemsPerPage}
            onChangePage={handleOnChangePage}
            onChangeRowsPerPage={handleOnChangeRowsPerPage}
            count={count}
          />
        )}
      </div>
      {selectedUser && (
        <>
          <Menu
            id="group-user-actions-menu"
            anchorEl={menuTarget}
            onClose={() => setMenuTarget(undefined)}
            open={Boolean(menuTarget)}
          >
            <MenuItem onClick={() => setDeleteDialogOpen(true)}>
              <Delete className={classes.icon} />
              Delete user
            </MenuItem>
          </Menu>
          <ConfirmDeleteUserDialog
            open={deleteDialogOpen}
            user={selectedUser}
            onClose={onCloseConfirmDialog}
          />
        </>
      )}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        autoHideDuration={2500}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{snackbarMessage}</span>}
      />
    </>
  );
}
