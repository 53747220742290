import { createMuiTheme, Theme } from "@material-ui/core/styles";

export const theme: Theme = createMuiTheme({
  palette: {
    primary: {
      main: "#182029",
    },
    secondary: {
      main: "#00A3E0",
      contrastText: "#FFF",
    },
    background: {
      default: "#FFF",
    },
  },
  typography: {
    fontFamily: "Gordita, sans-serif",
  },
});

export default theme;
