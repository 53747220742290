import React from "react";
import { KeyedArray, getKeyedString } from "../models/keyed";
import { Link } from "react-router-dom";
import DataTableEntries from "../models/DataTableEntries";
import Typography from "@material-ui/core/Typography";
import { accountClient } from "./http";
import { GroupList } from "../models/Group/GroupList";
import { Group } from "../models/Group/Group";
import { UserList, UserListItem } from "../models/User/UserList";
import { getUserListingActions } from "./http-user";

export async function getGroups(organisationId: string): Promise<{ count: number; groups: Group[] }> {
  const { data } = await accountClient.get<GroupList>(`/organisations/${organisationId}/groups/`);

  const orderedGroups = {
    count: data.total_items,
    groups: data.groups.sort(({ name: aName }, { name: bName }) => aName.localeCompare(bName))
  };

  return orderedGroups;
}

export async function getGroup(organisationId: string, groupId: string): Promise<Group> {
  const { data } = await accountClient.get<Group>(`/organisations/${organisationId}/groups/${groupId}/`);

  return data;
}

async function _addUserToGroup(organisationId: string, userId: string, groupId: string): Promise<void> {
  return accountClient.put(
    `/organisations/${encodeURI(organisationId)}/groups/${encodeURI(groupId)}/users/${encodeURI(userId)}/`
  );
}

export async function addUserToGroups(organisationId: string, userId: string, groupIds: string[]): Promise<void[]> {
  return Promise.all(groupIds.map(groupId => _addUserToGroup(organisationId, userId, groupId)));
}

export async function addUsersToGroup(organisationId: string, userIds: string[], groupId: string): Promise<void[]> {
  return Promise.all(userIds.map(userId => _addUserToGroup(organisationId, userId, groupId)));
}

export async function createGroup(organisationId: string, name: string, description: string): Promise<{ group_id: string }> {
  const { data } = await accountClient.post(`/organisations/${encodeURI(organisationId)}/groups/`, { name, description });
  return data;
}

export async function deleteGroup(organisationId: string, groupId: string): Promise<void> {
  await accountClient.delete(`/organisations/${encodeURI(organisationId)}/groups/${encodeURI(groupId)}/`);
}

export function renderGroupListing(
  groups: Group[],
  searchTerm: string,
  startItem: number,
  endItem: number,
  getGroupActions: (group: Group) => JSX.Element
): DataTableEntries {
  const filteredGroups = groups.filter((x) => !searchTerm || x.name.toLowerCase().includes(searchTerm.toLowerCase()));
  const keyedArr = filteredGroups.slice(startItem, endItem).map<KeyedArray>((group) => {
    const groupLink = (
      <Typography color="secondary">
        <Link color="inherit" to={`/manage/organisations/${group.organisation_id}/groups/${group.group_id}`}>
          {group.name}
        </Link>
      </Typography>
    );

    return {
      key: group.group_id,
      entries: [
        getKeyedString(groupLink, "name"),
        getKeyedString(group.description, "description"),
        getKeyedString(getGroupActions(group), "actions")
      ],
    };
  });

  return {
    keyedArr,
    count: filteredGroups.length,
    headers: ["Name", "Description", ""],
  };
}

export async function getGroupMembers(
  organisationId: string,
  groupId: string,
  page: number,
  size: number,
  getUserActions: (user: UserListItem) => JSX.Element,
  q?: string
): Promise<DataTableEntries> {
  const params = {
    page: page ? page + 1 : undefined,
    q,
    size,
  };

  const { data } = await accountClient.get<UserList>(`/organisations/${organisationId}/groups/${groupId}/users/`, {
    params,
  });

  return {
    keyedArr: getUserListingActions(data, getUserActions),
    count: data.total_items,
    headers: getUserHeaders(),
  };
}

function getUserHeaders() {
  return ["Name", "Email", "Last Login", "Logins", ""];
}
