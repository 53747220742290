
import { createStyles, Theme, makeStyles, Button, IconButton, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar } from "@material-ui/core";
import { GroupAdd as GroupAddIcon } from "@material-ui/icons";
import { AxiosError } from "axios";
import React, { useState } from "react";
import { createGroup } from "../../../services/http-group";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectOffset: {
      marginBottom: theme.spacing(4),
    },
    errorMessage: {
      color: "red",
      marginTop: theme.spacing(8),
    },
    icon: {
      margin: theme.spacing(1),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  })
);

interface CreateGroupDialogProps {
  organisationId: string;
  reloadGroups: () => void;
}

export default function CreateUserDialog({ organisationId, reloadGroups }: CreateGroupDialogProps) {
  const classes = useStyles();

  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [groupDescription, setGroupDescription] = useState<string>("");
  const [groupName, setGroupName] = useState<string>("");

  function handleGroupNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    setGroupName(event.target.value);
  }

  function handleGroupDescriptionChange(event: React.ChangeEvent<HTMLInputElement>) {
    setGroupDescription(event.target.value);
  }

  async function handleCreateGroup() {
    if (!groupName || !groupDescription) {
      const fields = [];
      if (!groupName) {
        fields.push("Group Name");
      }
      if (!groupDescription) {
        fields.push("Group Description");
      }

      setErrorMessage(`All fields are required. Please fill: ${fields.join(", ")}`);
      return;
    }

    setLoading(true);
    setErrorMessage("");

    try {
      await createGroup(organisationId, groupName, groupDescription);
    } catch (err) {
      const error = err as AxiosError<{ detail?: string }>;
      setErrorMessage(error.response?.data.detail || "Error creating group");
      setLoading(false);
      return;
    }

    setLoading(false);
    setSnackbarOpen(true);

    setTimeout(() => {
      reloadGroups();
    }, 1000);

    setOpen(false);
  }

  return (
    <div>
      <IconButton id="create-group-button" className={classes.icon} color="primary" onClick={() => setOpen(true)}>
        <GroupAddIcon />
      </IconButton>
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        id="create-group-dialog"
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title-create-group"
      >
        <DialogTitle id="form-dialog-title-create-group">Create group</DialogTitle>
        <DialogContent>
          <TextField
            margin="normal"
            autoFocus
            id="create-group-name"
            label="Group name"
            type="text"
            fullWidth
            onChange={handleGroupNameChange}
            value={groupName}
          />
          <TextField
            margin="normal"
            id="create-group-description"
            label="Group Description"
            type="text"
            fullWidth
            onChange={handleGroupDescriptionChange}
            value={groupDescription}
            className={classes.selectOffset}
          />

          {errorMessage && <DialogContentText className={classes.errorMessage}>{errorMessage}</DialogContentText>}
        </DialogContent>
        <DialogActions>
          <Button id="create-group-cancel" onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button id="create-group-create" disabled={loading} onClick={handleCreateGroup} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        autoHideDuration={2500}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">Group successfully created</span>}
      />
    </div>
  );
}
