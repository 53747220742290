import { Identity, Membership, MembershipGroup, MembershipOrganisation, User } from "../models/User/User";
import { UserListItem } from "../models/User/UserList";

export const getEmail = <T extends UserListItem = UserListItem>({ email }: T): string => email;
export const getUserId = <T extends UserListItem = UserListItem>({ user_id }: T): string => user_id;
export const getIdentities = <T extends UserListItem = UserListItem>({ identities }: T): Identity[] => identities;
export const getLastLogin = <T extends UserListItem = UserListItem>({ last_login }: T): null | string => last_login;
export const getLoginsCount = <T extends UserListItem = UserListItem>({ logins_count }: T): number => logins_count || 0;
export const getLastPasswordReset = <T extends UserListItem = UserListItem>({ last_password_reset }: T): null | string => last_password_reset;
export const getName = <T extends UserListItem = UserListItem>({ name }: T): string => name;
export const getPicture = <T extends UserListItem = UserListItem>({ picture }: T): string => picture;

export const getOrganisation = (membership: Membership): undefined | MembershipOrganisation => membership.organisation;
export const getOrganisationId = (membership: Membership): undefined | string => getOrganisation(membership)?.id;
export const getGroups = (membership: Membership): MembershipGroup[] => membership.groups || [];

export const isUserBlocked = <T extends UserListItem = UserListItem>({ blocked }: T): boolean => !!blocked;
export const isUserVerified = <T extends UserListItem = UserListItem>({ email_verified }: T): boolean => !!email_verified;

export const hasOrganisation = (membership: Membership): boolean => !!membership?.organisation?.id;

const PASSWORD_RESET_PROVIDERS = ["auth0"];

export const canResetPassword = (user: User): boolean =>
  !!getIdentities(user).filter(({ provider }) => PASSWORD_RESET_PROVIDERS.includes(provider)).length;
