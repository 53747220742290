import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Skeleton from "@material-ui/lab/Skeleton";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import GroupIcon from "@material-ui/icons/Group";
import { Link as RouterLink } from "react-router-dom";
import Products, { Product } from "../../models/products";
import { useAuth0 } from "../Auth0/AuthWrapper";
import ProductCard from "../Layout/ProductCard";
import { hasGroupName, hasSubscriptionId } from "../../helpers/authUser";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      [theme.breakpoints.up("sm")]: {
        fontWeight: "bold",
      },
      [theme.breakpoints.down("xs")]: {
        fontWeight: 400,
      },
      fontSize: 20,
      color: theme.palette.primary.main,
    },
    noSubs: {
      textAlign: "center"
    }
  })
);

export default function Home() {
  const classes = useStyles();
  const isMobile: boolean = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));
  const isCenter: boolean = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const { user, isSuperAdmin, isDelegatedAdmin } = useAuth0();
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    if (user) {
      setProducts(Products
        .filter(p =>
            (!hasGroupName(user, "AMPLYFI") || p.active) && hasSubscriptionId(user, p.appId)
        ))
    }
  }, [user]);

  function getGreeting() {
    if (user) {
      const name = user.name.split(" ")[0];
      if (process.env.REACT_APP_GREETINGS && process.env.REACT_APP_GREETINGS.length > 0) {
        const greetings = process.env.REACT_APP_GREETINGS.split(";");
        const randomGreeting = greetings[Math.floor(Math.random() * greetings.length)];
        return `${randomGreeting} ${name}`;
      }
      return `Hello ${name}`;
    }
  }

  return (
    <Container maxWidth="xl">
      {(isSuperAdmin || isDelegatedAdmin) && (
        <Grid container alignItems="flex-start" justify="flex-end" direction="row" spacing={3}>
          <Box paddingRight={3} paddingBottom={3}>
            <Link id="manage-users-and-groups-link" color="secondary" to={`/manage/users`} component={RouterLink}>
              <Button color="secondary" startIcon={<GroupIcon />}>
                Manage users and groups
              </Button>
            </Link>
          </Box>
        </Grid>
      )}
      <Grid container direction="column" alignItems="center" spacing={3}>
        <Grid item>
          <Typography className={classes.title} variant="h2">
            {getGreeting()}
          </Typography>
        </Grid>
        <Grid
          container
          item
          direction={isMobile ? "column-reverse" : "row-reverse"}
          spacing={1}
          justify={isCenter ? "center" : "flex-start"}
        >
          {user ? (
            products.length > 0 ? (
              products.map((product) => (
                <Grid key={product.id} item xs={12} sm={6} md="auto">
                  <ProductCard {...product} />
                </Grid>
              ))
            ) : (
              <Grid item xs={12} sm={6} md="auto" className={classes.noSubs}>
                <p>It looks like you don't have an active subscription.</p>
                <p><a href="https://amplyfi.com/support">Contact support</a></p>
              </Grid>
            )
          ) : (
            <>
              <Grid item xs={12} sm={6} md="auto">
                <Skeleton height={470} width={260} />
              </Grid>
              <Grid item xs={12} sm={6} md="auto">
                <Skeleton height={470} width={260} />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}
