import { User } from "@auth0/auth0-spa-js";

type Domains = "https://amplyfi.com";

const amplyfiDomain: Domains = "https://amplyfi.com";

interface Organisation {
  id: string;
  name: string;
}

interface Subscription {
  id: string;
  name: string;
}

type DomainMembership = Record<
  Domains,
  {
    membership: {
      groups: Organisation[];
      organisation: Organisation;
    };
    permissions: string[];
    subscriptions: Subscription[];
  }
>;

export interface AuthUser extends User, DomainMembership {}

const getPermissions = (user?: AuthUser): string[] =>
  user?.[amplyfiDomain]?.permissions || [];

export const getGroups = (user?: AuthUser): Organisation[] =>
  user?.[amplyfiDomain]?.membership?.groups || [];

type GetOrganisationFnOverload = {
  (user: AuthUser): Organisation;
  (user?: AuthUser): Organisation | undefined;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getOrganisation: GetOrganisationFnOverload = (user: any): any =>
  user?.[amplyfiDomain]?.membership?.organisation;

export const hasPermission = (user: AuthUser | undefined, permission: string): boolean =>
  getPermissions(user).includes(permission);

const getSubscriptions = (user?: AuthUser): Subscription[] =>
  user?.[amplyfiDomain]?.subscriptions || [];

export const hasGroupName = (user: AuthUser | undefined, groupName: string): boolean =>
    getOrganisation(user)?.name === groupName
    || !!getGroups(user).find(({ name }) => name === groupName);
  
export const hasSubscriptionId = (user: AuthUser | undefined, subscriptionId: string): boolean =>
  !!getSubscriptions(user).some(({ id }) => id === subscriptionId);

