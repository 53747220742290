import React from "react";
import ConfirmDialog from "../ConfirmDialog";
import { deleteUserFromGroup } from "../../../services/http-user";

interface ConfirmRemoveUserFromGroupDialogProps {
  open: boolean;
  userId: string;
  onClose: (options: { reload: boolean }) => void;
  groupName: string;
  groupId: string;
  organisationId: string;
  name: string;
  email: string;
}

export default function ConfirmRemoveUserFromGroupDialog({
  open,
  userId,
  onClose,
  groupName,
  groupId,
  organisationId,
  name,
  email,
}: ConfirmRemoveUserFromGroupDialogProps) {
  async function handleAction() {
    await deleteUserFromGroup(organisationId, groupId, userId);
  }

  return (
    <ConfirmDialog
      onClose={onClose}
      open={open}
      action={handleAction}
      dialogContentText={`Remove ${name} (${email}) from ${groupName}?`}
    />
  );
}