import React, { createRef, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Close from "@material-ui/icons/Close";
import { makeStyles, createStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formStyles: {
      margin: theme.spacing(1),
      position: "relative",
    },
    closeIcon: {
      position: "absolute",
      top: "3px",
      right: "3px",
      cursor: "pointer",
    },
    textField: {
      width: "100%",
    },
  })
);

interface SearchBarProps {
  placeholder: string;
  onSubmit: () => void;
  onChange: (value: string) => Promise<void>;
  searchTerm: string;
}

export default function SearchBar(props: SearchBarProps) {
  const classes = useStyles();
  const { placeholder, onSubmit, onChange, searchTerm } = props;
  const searchRef = createRef<HTMLInputElement>();

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.focus();
    }
  }, [searchRef]);

  function handleOnSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    onSubmit();
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    onChange(event.target.value);
  }

  async function handleOnClear() {
    await onChange("");
    onSubmit();
  }

  return (
    <form className={classes.formStyles} onSubmit={handleOnSubmit}>
      <TextField
        inputRef={searchRef}
        className={classes.textField}
        value={searchTerm}
        onChange={handleChange}
        placeholder={placeholder}
      />
      {searchTerm.length > 0 && <Close onClick={handleOnClear} className={classes.closeIcon} color="primary" />}
    </form>
  );
}
