import { createStyles, Theme, makeStyles, Button, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar } from "@material-ui/core";
import { GroupAdd as GroupAddIcon } from "@material-ui/icons";
import { AxiosError } from "axios";
import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import { searchUsers } from "../../../services/http-user";
import SelectOptions from "../../../models/SelectOptions";
import { addUsersToOrganisation } from "../../../services/http-organisation";
import { getUserId } from "../../../helpers/user";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorMessage: {
      color: "red",
      marginTop: theme.spacing(8),
    },
    dialogContent: {
      paddingBottom: theme.spacing(25),
    },
    icon: {
      margin: theme.spacing(1),
    },
    paper: {
      overflow: "visible",
    },
  })
);

interface AddUsersToOrganisationDialogProps {
  organisationId: string;
  reloadUsersAndGroups: () => void;
}

export default function AddUsersToOrganisationDialog(props: AddUsersToOrganisationDialogProps) {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [selectedUsers, setSelectedUsers] = useState<SelectOptions[]>([]);
  const { organisationId, reloadUsersAndGroups } = props;

  function handleClose() {
    setOpen(false);
    setSelectedUsers([]);
  }

  async function handleAddUsersToOrganisation() {
    setLoading(true);
    try {
      await addUsersToOrganisation(
        organisationId,
        selectedUsers.map((x) => x.value)
      );
    } catch (err) {
      const error = err as AxiosError<{ detail?: string }>;
      setLoading(false);
      setErrorMessage(error.response?.data.detail || "Error adding users to organisation");
      return;
    }
    setLoading(false);
    setSnackbarOpen(true);
    handleClose();
    setTimeout(() => {
      reloadUsersAndGroups();
    }, 1000);
  }

  async function handleSearchUsers(searchTerm: string) {
    if (searchTerm.length < 3) {
      searchTerm = "";
    }

    const { users } = await searchUsers(searchTerm, 0, 10);
    return users.map<SelectOptions>((user) => ({
      value: getUserId(user),
      label: `${user.name} (${user.email})`,
    }));
  }

  function handleOnSelectChange(selectedOptions: any) {
    setSelectedUsers(selectedOptions);
  }

  return (
    <div id="add-users-to-group-dialog">
      <IconButton color="primary" className={classes.icon} onClick={() => setOpen(true)}>
        <GroupAddIcon />
      </IconButton>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title-add-users-to-group"
        classes={{ paper: classes.paper }}
      >
        <DialogTitle id="form-dialog-title-add-users-to-group">Add users to organisation</DialogTitle>
        <DialogContent
          classes={{
            root: classes.paper,
          }}
        >
          <AsyncSelect
            id="search-users"
            isMulti
            autoFocus
            loadOptions={handleSearchUsers}
            defaultOptions
            value={selectedUsers}
            closeMenuOnScroll={false}
            closeMenuOnSelect={true}
            onChange={handleOnSelectChange}
            placeholder="Search... (3 chars min)"
          />
          {errorMessage && <DialogContentText className={classes.errorMessage}>{errorMessage}</DialogContentText>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button disabled={loading} onClick={handleAddUsersToOrganisation} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        autoHideDuration={2500}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">Users successfully added to group</span>}
      />
    </div>
  );
}
