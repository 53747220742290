import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import OrganisationMembers from "./OrganisationMembers";
import OrganisationGroups from "./OrganisationGroups";
import OrganisationSubscriptions from "./OrganisationSubscriptions";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useAuth0 } from "../../Auth0/AuthWrapper";
import GroupLibraries from "./OrganisationLibraries";
import { Chip } from "@material-ui/core";
import { getOrganisation } from "../../../services/http-organisation";
import { Organisation } from "../../../models/Organisation/Organisation";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    canvasStyle: {
      height: "100%",
      margin: theme.spacing(6),
      padding: theme.spacing(3),
    },
    avatar: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(2),
    },
    icon: {
      margin: theme.spacing(1),
    },
    tableHeader: {
      paddingTop: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    metaData: {
      marginLeft: theme.spacing(14),
      paddingTop: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    avatarTitle: {
      display: "flex",
      alignItems: "center",
      marginRight: theme.spacing(1),
    },
    tabs: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(2),
      borderBottom: "1px solid #e9e9e9"
    },
  })
);

export default function Organsiation() {
  const classes = useStyles();
  const [tab, setTab] = useState<number>(0);
  const [organisation, setOrganisation] = useState<Organisation>();
  const { isSuperAdmin } = useAuth0();
  const history = useHistory();
  const { organisationId } = useParams<{ groupId?: string; organisationId: string }>();

  async function fetchOrg() {
    const organisationResponse = await getOrganisation(organisationId);
    setOrganisation(organisationResponse);
  }

  useEffect(() => {
    resetTab();

    if (organisationId) {
      fetchOrg();
    }
    // eslint-disable-next-line
  }, [organisationId]);

  function resetTab() {
    setTab(0);
  }

  function handleTabChange(_: React.ChangeEvent<unknown>, value: number) {
    setTab(value);
  }

  return (
    <>
      <Grid container className={classes.tableHeader} direction="row">
        <Grid item className={classes.avatarTitle}>
          <IconButton onClick={() => history.goBack()}>
            <ArrowBackIcon />
          </IconButton>
          <Avatar className={classes.avatar}>{organisation?.name?.charAt(0) || ""}</Avatar>
          <Grid container direction="column">
            <Grid item>
              <Grid container direction="row">
                <Typography variant="h6">{organisation?.name || ""}</Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Chip label="Organisation" size="small" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.metaData}>
        <Typography variant="caption">{organisation?.description ? `${organisation.description}` : ""}</Typography>
      </Grid>
      <Tabs value={tab} onChange={handleTabChange} className={classes.tabs}>
        <Tab label="Users" />
        {isSuperAdmin && (<Tab label="Subscriptions" />)}
        <Tab label="Groups" />
        {isSuperAdmin && (<Tab label="Libraries" />)}
      </Tabs>
      {tab === 0 && <OrganisationMembers />}
      {tab === 1 && isSuperAdmin && <OrganisationSubscriptions />}
      {tab === 2 && <OrganisationGroups />}
      {tab === 3 && isSuperAdmin && <GroupLibraries />}
    </>
  );
}
