import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TopAppBar from "./TopAppBar";
import { useLocation } from "react-router-dom";

import useStylesBase, { ToolbarHeight, DrawerWidth } from "../../styles/styles-base";
import clsx from "clsx";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flexGrow: 1,
      marginTop: ToolbarHeight + theme.spacing(4),
      paddingLeft: (drawer) => (drawer ? DrawerWidth : 0),
      overflow: "auto",
    },
  })
);

interface MainProps {
  children: React.ReactNode;
}

export default function Main(props: MainProps) {
  const { pathname } = useLocation();
  const classes = useStyles(displayDrawer());
  const classesBase = useStylesBase();

  function displayDrawer() {
    const paths: string[] = pathname.split("/");
    return paths[1] !== "" && paths[1] !== "welcome" && paths[1] !== "account";
  }

  return (
    <div className={clsx(classesBase.root, classesBase.overrides)}>
      <TopAppBar />
      <main className={classes.content}>
        <Grid container direction="column" wrap="nowrap">
          {props.children}
        </Grid>
      </main>
    </div>
  );
}
