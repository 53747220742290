import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import MUISkeleton from "@material-ui/lab/Skeleton";
import { Theme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export default function Skeleton() {
  const isMobile: boolean = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));
  const isCenter: boolean = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const height: number = 560;
  const width: number = 260;

  return (
    <Container maxWidth="xl">
      <Grid container direction="column" alignItems="center" spacing={3}>
        <Grid
          container
          item
          direction={isMobile ? "column" : "row"}
          spacing={1}
          justify={isCenter ? "center" : "flex-start"}
        >
          <Grid item xs={12} sm={6} md="auto">
            <MUISkeleton height={height} width={width} />
          </Grid>
          <Grid item xs={12} sm={6} md="auto">
            <MUISkeleton height={height} width={width} />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
