import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { ReactComponent as LockIcon } from "../../assets/lock.svg";
import { ReactComponent as ShareIcon } from "../../assets/share.svg";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      [theme.breakpoints.down("xs")]: {
        width: 130,
      },
      [theme.breakpoints.up("sm")]: {
        width: 190,
        "& path": {
          fill: "white",
        },
      },
    },
    logoDisabled: {
      [theme.breakpoints.down("xs")]: {
        width: 120,
        "& path": {
          fill: "rgba(0,0,0,0.4)",
        },
      },
      [theme.breakpoints.up("sm")]: {
        width: 190,
        "& path": {
          fill: theme.palette.primary.main,
        },
      },
    },
    logoComingSoon: {
      [theme.breakpoints.down("xs")]: {
        width: 120,
        "& path": {
          fill: "rgba(0,0,0,0.4)",
        },
      },
      [theme.breakpoints.up("sm")]: {
        width: 190,
        "& path": {
          fill: "rgba(0,0,0,0.2)",
        },
      },
    },
    productHeader: {
      alignItems: "center",
      background: "#FFF",
      borderBottom: "1px solid #ccc",
      display: "flex",
      height: 96,
      justifyContent: "center"
    },
    productHeaderDisabled: {
      display: "flex",
      position: "relative",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      background: "#FFF",
      height: 96,
      borderBottom: "1px solid #CCCCCC",
    },
    productHeaderComingSoon: {
      display: "flex",
      position: "relative",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      background: "#FFF",
      height: 96,
      borderBottom: "1px solid rgba(0,0,0,0.1)",
    },
    headerLock: {
      position: "absolute",
      right: theme.spacing(2),
      bottom: theme.spacing(2),
      width: 15,
      height: 20,
      "& path": {
        fill: "rgba(0,0,0,0.2)",
      },
    },
    listItem: {
      minHeight: 40,
    },
    listItemIcon: {
      minWidth: theme.spacing(4),
      color: "#41D3BD",
    },
    listItemIconDisabled: {
      minWidth: theme.spacing(4),
      color: "#CCCCCC",
    },
    listItemText: {
      "& .MuiTypography-body1": {
        fontSize: 13,
        lineHeight: 1.4,
        textAlign: "center",
      },
    },
    listItemHighlight: {
      "& .MuiTypography-body1": {
        fontWeight: 'bold',
      },
    },
    dvButton: {
      height: 50,
      marginTop: theme.spacing(2),
      textTransform: "initial",
      backgroundColor: "#2861FB",
      "& .MuiButton-label": {
        color: "#FFFFFF !important",
      },
    },
    dvButtonComingSoon: {
      height: 50,
      textTransform: "initial",
      color: "rgba(0,0,0,0.2)",
      marginTop: theme.spacing(2),
      borderColor: "rgba(0,0,0,0.2)",
      "& .MuiButton-label": {
        color: "rgba(0,0,0,0.2) !important",
      },
    },
    mobileOpenContainer: {
      background: theme.palette.primary.main,
      borderRadius: 7,
      width: 33,
      height: 33,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& path": {
        fill: "#FFF",
      },
    },
    mobileOpenContainerLocked: {
      background: "#F2F2F2",
      borderRadius: 7,
      width: 33,
      height: 33,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& path": {
        fill: theme.palette.primary.main,
      },
    },
    mobileOpenIcon: {
      width: "40%",
      marginLeft: 3,
      marginBottom: 2,
    },
    mobileCardLink: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    defaultCard: {
      position: "relative",
    },
    comingSoonCard: {
      position: "relative",
      [theme.breakpoints.up("lg")]: {
        marginLeft: theme.spacing(8),
      },
    },
    comingSoonText: {
      color: "rgba(0,0,0,0.2)",
    },
    buttonText: {
      color: "#",
    },
  })
);

interface ProductCardProps {
  id: string;
  name: string;
  logo: any;
  link: string;
  comingSoon?: undefined | boolean;
  features: string[];
  disabled?: undefined | boolean;
}

export default function ProductCard(props: ProductCardProps) {
  const classes = useStyles(props);
  const { disabled, comingSoon, link, logo, features } = props;
  const Logo = logo;

  let buttonText = "Open Application";
  let logoClass = classes.logo;
  let headerClass = classes.productHeader;

  if (disabled) {
    logoClass = classes.logoDisabled;
    headerClass = classes.productHeaderDisabled;
    buttonText = "Request Access";
  }

  if (comingSoon) {
    logoClass = classes.logoComingSoon;
    headerClass = classes.productHeaderComingSoon;
    buttonText = "Coming Soon";
  }

  const productContent = (
    <>
      <div className={headerClass}>
        <Logo className={logoClass} />
        {(comingSoon || disabled) && <LockIcon className={classes.headerLock} />}
      </div>
      <CardContent style={{ margin: "0 auto", maxWidth: 260 }}>
        <List disablePadding>
          {features.map((feature, i) => (
            <ListItem key={feature} disableGutters className={classes.listItem}>
              <ListItemText className={clsx(classes.listItemText, i < 1 ? classes.listItemHighlight : undefined)}>{feature}</ListItemText>
            </ListItem>
          ))}
        </List>
        <Button
          className={comingSoon ? classes.dvButtonComingSoon : classes.dvButton}
          variant={disabled || comingSoon ? "outlined" : "contained"}
          color="primary"
          fullWidth
          href={disabled ? "https://amplyfi.com/contact" : link}
        >
          {buttonText}
        </Button>
      </CardContent>
    </>
  );

  const mobileContent = (
    <Grid container direction="row">
      <Grid container item xs={2} alignItems="center" justify="center">
        <div className={disabled || comingSoon ? classes.mobileOpenContainerLocked : classes.mobileOpenContainer}>
          {disabled || comingSoon ? <LockIcon /> : <ShareIcon className={classes.mobileOpenIcon} />}
        </div>
      </Grid>
      <Box marginLeft={1}>
        <Logo className={logoClass} />
        <Typography
          display="block"
          color={comingSoon ? "textSecondary" : "secondary"}
          style={{ fontSize: 13 }}
          variant="body2"
        >
          {buttonText}
        </Typography>
      </Box>
    </Grid>
  );

  return (
    <Card className={comingSoon ? classes.comingSoonCard : classes.defaultCard}>
      <Hidden smUp>
        {mobileContent}
        <Link className={classes.mobileCardLink} href={link} />
      </Hidden>
      <Hidden xsDown>{productContent}</Hidden>
    </Card>
  );
}
