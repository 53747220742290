import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

export default function Loading() {
  const height: number = 43;
  const width: string = "100%";

  return (
    <>
      <Skeleton height={height} width={width} />
      <Skeleton height={height} width={width} />
      <Skeleton height={height} width={width} />
      <Skeleton height={height} width={width} />
      <Skeleton height={height} width={width} />
    </>
  );
}
