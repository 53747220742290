import React, { useState } from "react";
import { createStyles, Theme, makeStyles, Button, IconButton, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar } from "@material-ui/core";
import { Add as AddIcon, Business as BusinessIcon } from "@material-ui/icons";
import { AxiosError } from "axios";
import { createOrganisation } from "../../../services/http-organisation";
import { useAuth0 } from "../../Auth0/AuthWrapper";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectOffset: {
      marginBottom: theme.spacing(4),
    },
    errorMessage: {
      color: "red",
      marginTop: theme.spacing(8),
    },
    icon: {
      margin: theme.spacing(1),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  })
);

interface CreateOrganisationDialogProps {
  reloadOrganisations: () => void;
}

export default function CreateOrganisationDialog(props: CreateOrganisationDialogProps) {
  const classes = useStyles();
  const { reloadOrganisations } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [organisationDescription, setOrganisationDescription] = useState<string>("");
  const [organisationName, setOrganisationName] = useState<string>("");
  const { isSuperAdmin } = useAuth0();

  function handleOrganisationNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    setOrganisationName(event.target.value);
  }

  function handleOrganisationDescriptionChange(event: React.ChangeEvent<HTMLInputElement>) {
    setOrganisationDescription(event.target.value);
  }

  function resetForm() {
    setOrganisationName("");
    setOrganisationDescription("");
  }

  async function handleCreateOrganisation() {

    if (!organisationName || !organisationDescription) {
      const fields = [];
      if (!organisationName) {
        fields.push("Organisation Name");
      }
      if (!organisationDescription) {
        fields.push("Organisation Description");
      }
      setErrorMessage(`All fields are required. Please fill: ${fields.join(", ")}`);
      return;
    }

    setLoading(true);
    setErrorMessage("");

    try {
      await createOrganisation(organisationName, organisationDescription);
    } catch (err) {
      const error = err as AxiosError<{ detail?: string }>;
      setErrorMessage(error.response?.data.detail || "Error creating an organisation");
      setLoading(false);
      return;
    }

    setLoading(false);
    setSnackbarOpen(true);

    setTimeout(() => {
      reloadOrganisations();
    }, 1000);

    setOpen(false);
    resetForm();
  }


  return (
    <>
      {isSuperAdmin &&
        <div>
          <IconButton id="create-organisation-button" className={classes.icon} color="primary" onClick={() => setOpen(true)}>
            <AddIcon />
            <BusinessIcon />
          </IconButton>
          <Dialog
            maxWidth="sm"
            fullWidth={true}
            id="create-organisation-dialog"
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="form-dialog-title-create-organisation"
          >
            <DialogTitle id="form-dialog-title-create-organisation">Create an organisation</DialogTitle>
            <DialogContent>
              <TextField
                margin="normal"
                autoFocus
                id="create-organisation-name"
                label="Organisation name"
                type="text"
                fullWidth
                onChange={handleOrganisationNameChange}
                value={organisationName}
              />
              <TextField
                margin="normal"
                id="create-organisation-description"
                label="Organisation Description"
                type="text"
                fullWidth
                onChange={handleOrganisationDescriptionChange}
                value={organisationDescription}
                className={classes.selectOffset}
              />

              {errorMessage && <DialogContentText className={classes.errorMessage}>{errorMessage}</DialogContentText>}
            </DialogContent>
            <DialogActions>
              <Button id="create-organisation-cancel" onClick={() => setOpen(false)} color="primary">
                Cancel
              </Button>
              <Button id="create-organisation-create" disabled={loading} onClick={handleCreateOrganisation} color="primary">
                Create
              </Button>
            </DialogActions>
          </Dialog>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={snackbarOpen}
            onClose={() => setSnackbarOpen(false)}
            autoHideDuration={2500}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
            message={<span id="message-id">Organisation successfully created</span>}
          />
        </div>
      }
    </>
  );
}
