import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import GroupMembers from "./GroupMembers";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { getGroup } from "../../../services/http-group";
import { Chip } from "@material-ui/core";
import { getOrganisation } from "../../../services/http-organisation";
import { Organisation } from "../../../models/Organisation/Organisation";
import { Group } from "../../../models/Group/Group";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    canvasStyle: {
      height: "100%",
      margin: theme.spacing(6),
      padding: theme.spacing(3),
    },
    avatar: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(2),
    },
    icon: {
      margin: theme.spacing(1),
    },
    tableHeader: {
      paddingTop: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    metaData: {
      marginLeft: theme.spacing(14),
      paddingTop: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    avatarTitle: {
      display: "flex",
      alignItems: "center",
      marginRight: theme.spacing(1),
    },
    tabs: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(2),
      borderBottom: "1px solid #e9e9e9"
    },
  })
);

export default function GroupComponent() {
  const classes = useStyles();
  const history = useHistory();
  const { organisationId, groupId } = useParams<{ groupId: string; organisationId: string }>();
  const [tab, setTab] = useState<number>(0);
  const [organisation, setOrganisation] = useState<Organisation>();
  const [group, setGroup] = useState<Group>();

  async function fetchGroup() {
    const [org, grp] = await Promise.all(
      [
        getOrganisation(organisationId),
        getGroup(organisationId, groupId),
      ]);
    setOrganisation(org);
    setGroup(grp as Group);
  }

  function resetTab() {
    setTab(0);
  }

  function handleTabChange(_: React.ChangeEvent<unknown>, value: number) {
    setTab(value);
  }
  
  useEffect(() => {
    resetTab();
    if (groupId) {
      fetchGroup();
    }
    // eslint-disable-next-line
  }, [groupId]);

  return (
    <>
      <Grid container className={classes.tableHeader} direction="row">
        <Grid item className={classes.avatarTitle}>
          <IconButton onClick={() => history.goBack()}>
            <ArrowBackIcon />
          </IconButton>
          <Avatar className={classes.avatar}>{group?.name.charAt(0) || ""}</Avatar>
          <Grid container direction="column">
            <Grid item>
              <Grid container direction="row">
                <Typography variant="h6">{group?.name || ""}</Typography>
              </Grid>
            </Grid>
            <Grid item>
              {organisation && <Chip label={`Group of ${organisation.name}`} size="small" />}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.metaData}>
        <Typography variant="caption">{group?.description || ""}</Typography>
      </Grid>
      <Tabs value={tab} onChange={handleTabChange} className={classes.tabs}>
        <Tab label="Users" />
      </Tabs>
      {groupId && (
        <>
          {tab === 0 && 
            <GroupMembers groupId={groupId} groupName={group?.name || ""} organisationId={organisationId} />}
        </>
      )}
    </>
  );
}