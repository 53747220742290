import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import { KeyedArray } from "../../models/keyed";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      marginTop: theme.spacing(3),
      overflowX: "auto",
    },
    table: {
      minWidth: 700,
    },
  })
);

interface DataTableProps {
  headers: string[];
  data: KeyedArray[];
  page: number;
  itemsPerPage: number;
  onChangePage: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => void;
  onChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  count: number;
  rowsPerPageOptions: number[];
}

export default function DataTable(props: DataTableProps) {
  const classes = useStyles();
  const { headers, data, page, count, onChangePage, onChangeRowsPerPage, itemsPerPage, rowsPerPageOptions } = props;

  return (
    <>
      <Table id="data-table" className={classes.table}>
        <TableHead>
          <TableRow>
            {headers.map((header, i) => (
              <TableCell key={`${header}-${i}`}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((keyedArray) => {
            return (
              <TableRow key={keyedArray.key}>
                {keyedArray.entries.map((keyedString) => (
                  <TableCell padding="default" size="medium" key={keyedString.key} align="left">
                    {keyedString.entry}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <TablePagination
        component="div"
        count={count}
        page={page}
        onChangePage={onChangePage}
        rowsPerPage={itemsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        onChangeRowsPerPage={onChangeRowsPerPage}
        labelDisplayedRows={({ from, to, count }) => (count < 9000 ? `${from}-${to} of ${count}` : `${from}-${to}`)}
      />
    </>
  );
}
