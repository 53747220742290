import React from "react";
import Library from "../../../models/Library/Library";
import { pauseLibrary, unpauseLibrary } from "../../../services/http-library";
import ConfirmDialog from "../ConfirmDialog";

interface ConfirmPauseLibraryDialogProps {
  library: Library;
  onClose: () => void;
  onSuccess: () => void;
  open: boolean;
  organisationId: string;
}

export default function ConfirmPauseLibraryDialog({
  library: { harvestingPaused, id, name },
  onClose,
  onSuccess,
  open,
  organisationId
}: ConfirmPauseLibraryDialogProps) {
  async function handleAction() {
    await (harvestingPaused ? unpauseLibrary : pauseLibrary)(
      organisationId,
      id,
    );
    onSuccess();
    onClose();
  }

  return (
    <ConfirmDialog
      onClose={onClose}
      open={open}
      action={handleAction}
      dialogContentText={`${harvestingPaused ? "Unpause" : "Pause"} harvesting into ${name}?`}
    />
  );
}
