import React, { useState } from "react";
import MuiAppBar, { AppBarProps } from "@material-ui/core/AppBar";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";

interface AmplyfiAppBarProps extends AppBarProps {
  logo: React.ReactElement;
  searchElement?: React.ReactElement;
  buttons: React.ReactElement[];
  hideSearch?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      justifyContent: "space-between",
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(0, 6)
      },
      "& > img": {
        [theme.breakpoints.up("md")]: {
          margin: theme.spacing(1, 0)
        },
        width: 150
      }
    },
    icons: {
      display: "flex",
      flexGrow: 1,
      alignItems: "center",
      justifyContent: "flex-end",
      [theme.breakpoints.down("xs")]: {
        justifyContent: "space-evenly"
      },
      "& > *": {
        margin: theme.spacing(0, 2),
        cursor: "pointer",
        "&:last-child": {
          marginRight: 0
        },
        [theme.breakpoints.down("xs")]: {
          margin: theme.spacing(0, 1)
        }
      }
    },
    searchBarContainer: {
      flexGrow: 1
    },
    iconButton: {
      color: "#FFF"
    }
  })
);

function AppBar(props: AmplyfiAppBarProps) {
  const classes = useStyles();
  const { logo, searchElement, buttons } = props;
  const hideSearch = Boolean(props.hideSearch);
  const [displaySearchElement, setDisplaySearchElement] = useState(false);

  const search = <div className={classes.searchBarContainer}>{searchElement}</div>;

  return (
    <MuiAppBar position="fixed" elevation={0}>
      <Toolbar className={classes.header}>
        {!displaySearchElement && logo}
        <Hidden mdUp>{displaySearchElement && search}</Hidden>
        {!hideSearch && <Hidden smDown>{search}</Hidden>}
        <div className={classes.icons}>
          <Hidden mdUp>
            {!hideSearch && (
              <IconButton className={classes.iconButton} onClick={() => setDisplaySearchElement(!displaySearchElement)}>
                <SearchIcon />
              </IconButton>
            )}
          </Hidden>
          {!displaySearchElement && buttons}
        </div>
      </Toolbar>
    </MuiAppBar>
  );
}

export default AppBar;
