import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DataTable from "../../Layout/DataTable";
import SearchBar from "../../Layout/SearchBar";
import { KeyedArray } from "../../../models/keyed";
import { getGroups, renderGroupListing } from "../../../services/http-group";
import Loading from "../../Layout/Loading";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DeleteForever from "@material-ui/icons/DeleteForever";
import Snackbar from "@material-ui/core/Snackbar";
import ConfirmDeleteGroupDialog from "../../Dialogs/User/ConfirmDeleteGroupDialog";
import CreateGroupDialog from "../../Dialogs/User/CreateGroupDialog";
import { Group } from "../../../models/Group/Group";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    userActions: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    actions: {
      cursor: "pointer",
      color: theme.palette.primary.main,
    },
    icon: {
      margin: theme.spacing(1),
    },
    groupsTable: {
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  })
);

export default function OrganisationGroups() {
  const classes = useStyles();
  const { organisationId } = useParams<{ organisationId: string }>();

  const [search, setSearch] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [headers, setHeaders] = useState<string[]>([]);
  const [data, setData] = useState<KeyedArray[]>([]);
  const [page, setPage] = useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [count, setCount] = useState<number>(0);
  const [gridLoading, setGridLoading] = useState(false);
  const [groupMenuTarget, setGroupMenuTarget] = useState<undefined | HTMLElement>(undefined);
  const [selectedGroupEntry, setSelectedGroupEntry] = useState<Group | undefined>(undefined);
  const [deleteGroupDialogOpen, setDeleteGroupDialogOpen] = useState<boolean>(false);
  const [groupSnackbarOpen, setGroupSnackbarOpen] = useState<boolean>(false);
  const [groups, setGroups] = useState<Group[]>();

  async function fetchGroups() {
    const { groups } = await getGroups(organisationId);
    setGroups(groups);
  }
  
  useEffect(() => {
    setGridLoading(true);
    fetchGroups();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (groups) {
      setNestedGroups();
    }
    // eslint-disable-next-line
  }, [groups, page, itemsPerPage]);

  function handleOnSearchTermChange(searchTerm: string) {
    return Promise.resolve(setSearchTerm(searchTerm));
  }

  function handleOnChangePage(event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) {
    setPage(page);
  }

  function handleOnChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setItemsPerPage(Number(event.target.value));
  }

  async function setNestedGroups() {
    const startItem = page * itemsPerPage;
    const endItem = startItem + itemsPerPage;
    const keyedGroupData = renderGroupListing(groups || [], searchTerm, startItem, endItem, getGroupActions);

    setData(keyedGroupData.keyedArr);
    setCount(keyedGroupData.count);
    setHeaders(keyedGroupData.headers);
    setGridLoading(false);
  }

  function getGroupActions(groupEntry: Group) {
    return (
      <IconButton size="small" onClick={(event) => toggleGroupActions(event, groupEntry)} className={classes.actions}>
        <MoreVertIcon />
      </IconButton>
    );
  }

  function toggleGroupActions(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, groupEntry: Group) {
    setGroupMenuTarget(event.currentTarget);
    setSelectedGroupEntry(groupEntry);
  }

  function onCloseConfirmGroupDialog(options: { reload: boolean }) {
    setDeleteGroupDialogOpen(false);
    setGroupMenuTarget(undefined);
    if (options.reload) {
      fetchGroups();
      setGroupSnackbarOpen(true);
    }
  }

  return (
    <>
      <Grid container className={classes.userActions} justify="space-between">
        {groups && (
          <Grid item xs={8}>
            {search ? (
              <SearchBar
                placeholder="Search... (3 chars min)"
                searchTerm={searchTerm}
                onChange={handleOnSearchTermChange}
                onSubmit={setNestedGroups}
              />
            ) : (
              <></>
            )}
          </Grid>
        )}
        <Grid item>
          <Grid container direction="row">
            {groups && (
              <>
                <IconButton
                  id="search-users-button"
                  className={classes.icon}
                  color="primary"
                  onClick={() => setSearch(!search)}
                >
                  <SearchIcon />
                </IconButton>
                <CreateGroupDialog organisationId={organisationId} reloadGroups={fetchGroups} />
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.groupsTable}>
        {gridLoading ? (
          <Loading />
        ) : (
          <DataTable
            rowsPerPageOptions={[10, 25]}
            headers={headers}
            data={data}
            page={page}
            itemsPerPage={itemsPerPage}
            onChangePage={handleOnChangePage}
            onChangeRowsPerPage={handleOnChangeRowsPerPage}
            count={count}
          />
        )}
      </div>
      <Menu
        id="group-actions-menu"
        anchorEl={groupMenuTarget}
        onClose={() => setGroupMenuTarget(undefined)}
        open={Boolean(groupMenuTarget)}
      >
        <MenuItem onClick={() => setDeleteGroupDialogOpen(true)}>
          <DeleteForever className={classes.icon} />
          Delete Group
        </MenuItem>
      </Menu>
      <ConfirmDeleteGroupDialog
        open={deleteGroupDialogOpen}
        group={selectedGroupEntry as Group}
        onClose={onCloseConfirmGroupDialog}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={groupSnackbarOpen}
        onClose={() => setGroupSnackbarOpen(false)}
        autoHideDuration={2500}
        ContentProps={{
          "aria-describedby": "group-delete-message",
        }}
        message={<span id="group-delete-message">Group successfully deleted</span>}
      />
    </>
  );
}
