import React from "react";
import ConfirmDialog from "../ConfirmDialog";
import { Group } from "../../../models/Group/Group";
import { deleteGroup } from "../../../services/http-group";

interface ConfirmDeleteGroupDialogProps {
  open: boolean;
  group: Group;
  onClose: (options: { reload: boolean }) => void;
}

export default function ConfirmDeleteGroupDialog(props: ConfirmDeleteGroupDialogProps) {
  const { open, group, onClose } = props;

  function handleClose(options: { reload: boolean }) {
    onClose(options);
  }

  async function handleDeleteGroup() {
    const { group_id, organisation_id } = group;
    await deleteGroup(organisation_id, group_id);
  }

  return group ? (
    <ConfirmDialog
      onClose={handleClose}
      open={open}
      action={handleDeleteGroup}
      dialogContentText={`Permanently delete ${group.name}?`}
    />
  ) : (
    <></>
  );
}
