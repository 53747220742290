import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Switch from "@material-ui/core/Switch";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Loading from "../../Layout/Loading";
import {
  getSubscriptionsForOrganisation,
  addSubscriptionForOrganisation,
  deleteSubscriptionForOrganisation,
} from "../../../services/http-subscription";
import {
  removeRoleForOrganisation,
  addRoleForOrganisation,
  getRolesForOrganisation,
  getApplicationRoles
} from "../../../services/http-role";
import products from "../../../models/products";
import { DatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from "moment";
import SubscriptionStatus from "../../../models/Group/SubscriptionStatus";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { Box, Checkbox, Collapse, ListItemIcon, Typography } from "@material-ui/core";
import { SubscriptionRole } from "../../../models/Role/SubscriptionRole";
import { useParams } from "react-router-dom";
import { SubscriptionList } from "../../../models/Subscription/SubscriptionList";
import { Role } from "../../../models/Role/Role";
import useOrganisationSubscription from "../../../hooks/useOrganisationSubscription";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    expiresText: {
      marginRight: theme.spacing(1),
    },
    userTable: {
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  })
);

const localeCompare = (a: string, b: string): number => a.localeCompare(b);

export default function GroupSubscriptions() {
  const classes = useStyles();
  const { organisationId } = useParams<{ organisationId: string }>();
  const { data: stripeSubscription } = useOrganisationSubscription(organisationId);

  const [gridLoading, setGridLoading] = useState<boolean>(false);
  const [subscriptions, setSubscriptions] = useState<SubscriptionStatus[]>(getSubscriptions());

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [organisationId]);

  function getSubscriptions() {
    return products.map<SubscriptionStatus>(({ appId, disabled = false, id, name }) => ({
      appId,
      disabled,
      id,
      name,
    }));
  }

  async function fetchData() {
    setGridLoading(true);
    const [
      subscriptionsForOrganisation,
      organsationRoles,
    ]: [
      SubscriptionList["subscriptions"],
      Role[],
    ] = await Promise.all([
      getSubscriptionsForOrganisation(organisationId),
      getRolesForOrganisation(organisationId),
    ]);

    const applicationRoles = await Promise.all<Role[]>(
      products.map((p) => p.appId).map((applicationId) => getApplicationRoles(applicationId)),
    );

    setGridLoading(false);
    setSubscriptions(
      subscriptions.map((subscription) => {
        const s = subscriptionsForOrganisation.find((sub) => sub.application_id === subscription.appId);
        if (!s) {
          return subscription;
        }
        return {
          ...subscription,
          expirationDate: moment.utc(s.expiry).toDate(),
          isActivated: moment.utc().isBefore(s.expiry),
          appRoles: applicationRoles.flat().filter(r => r.application_id === subscription.appId).map((role) => {
            return {
              ...role,
              _id: role.role_id,
              applicationId: role.application_id,
              isActivated: !!organsationRoles.find(r => r.role_id === role.role_id),
              isDisabled: ["Analysis", "Research"].includes(role.name),
            };
          }),
        };
      })
    );
  }

  async function handleDateChange(subscription: SubscriptionStatus, newDate: MaterialUiPickersDate) {
    if (!newDate) {
      return;
    }
    await addSubscriptionForOrganisation(organisationId, subscription.appId, newDate.utc().toDate());
    fetchData();
  }

  async function handleSubscriptionSwitch(subscription: SubscriptionStatus, isActivated: boolean) {
    if (!isActivated) {
      await deleteSubscriptionForOrganisation(organisationId, subscription.appId);
      setSubscriptions(
        subscriptions.map((sub) => {
          if (sub.id === subscription.id) {
            return {
              ...sub,
              isActivated: false,
              expirationDate: undefined,
            };
          }
          return sub;
        })
      );
    } else {
      const expiration = moment().add("1", "month").endOf("day").toDate();
      await addSubscriptionForOrganisation(organisationId, subscription.appId, expiration);
      await fetchData();
    }
  }

  async function handleRoleSwitch(role: SubscriptionRole, isActivated: boolean) {
    if (!isActivated) {
      await removeRoleForOrganisation(organisationId, role._id);
    } else {
      await addRoleForOrganisation(organisationId, role._id);
    }
    fetchData();
  }

  function isDisabled(role: SubscriptionRole, roles: SubscriptionRole[]) {
    // if there are any enterprise roles that include the word Enterprise, disable
    // unless the current role contains Enterprise, but is not exactly enterprise
    
    const enterpriseRoles = roles.filter((r) => r.isActivated && r.name.includes("Enterprise"));
    const currentRoleIncludesEnterprise = role.name.includes("Enterprise");
    const currentRoleIsEnterprise = role.name === "Enterprise";
    if (enterpriseRoles.length === 0) {
      return true;
    } else {
      if (currentRoleIsEnterprise) {
        return true;
      }
      if (currentRoleIncludesEnterprise) {
        return false;
      }
      return true;
    }
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <div className={classes.userTable}>
        {gridLoading ? (
          <Loading />
        ) : (
          <>
            {stripeSubscription && <Box padding={2}>
              <Typography variant="h6">This subscription is managed by Stripe</Typography>
              <Typography variant="body2">You can modify the subscription at <a target="_blank" rel="noopener noreferrer" href="https://dashboard.stripe.com/dashboard">https://dashboard.stripe.com/dashboard</a></Typography>
            </Box>
            }
          <List>
            {subscriptions.map((subscription) => (
              <React.Fragment key={subscription.id}>
                <ListItem disabled={!!stripeSubscription && subscription.name === "DeepInsight"}>
                  <ListItemText primary={subscription.name} />
                  <ListItemSecondaryAction>
                    {subscription.isInherited && (
                      <span className={classes.expiresText}>(Inherited)</span>
                    )}
                    {subscription.isActivated === false && subscription.expirationDate && (
                      <span
                        className={classes.expiresText}
                        title={moment.utc(subscription.expirationDate).format("dddd, MMMM Do YYYY, h:mm:ss a")}
                      >
                        expired {moment.utc(subscription.expirationDate).fromNow()}
                      </span>
                    )}
                    {subscription.isActivated === true && (
                      <>
                        <span className={classes.expiresText}>expires at</span>
                        <DatePicker
                          value={moment.utc(subscription.expirationDate).toDate()}
                          disabled={subscription.disabled || (!!stripeSubscription && subscription.name === "DeepInsight")}
                          format="DD/MM/YYYY"
                          onChange={(newDate) => handleDateChange(subscription, newDate)}
                        />
                      </>
                    )}
                    <Switch
                      disabled={subscription.disabled || (!!stripeSubscription && subscription.name === "DeepInsight")}
                      checked={subscription.isActivated || false}
                      onChange={(_, value) => handleSubscriptionSwitch(subscription, value)}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                <Collapse in={subscription.isActivated} timeout="auto" unmountOnExit>
                  <List key={subscription.appId}>
                    {subscription.appRoles?.sort((a, b) => localeCompare(a.name, b.name))
                      .map((role) => (
                      <ListItem disabled={stripeSubscription ? isDisabled(role, subscription.appRoles || []) : false} className={classes.nested} key={role._id}>
                        <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={role.isActivated || false}
                          disabled={role.isDisabled || stripeSubscription ? isDisabled(role, subscription.appRoles || []) : false}
                          onChange={(_, value) => handleRoleSwitch(role, value)}
                        />
                        </ListItemIcon>
                        <ListItemText primary={role.name} secondary={role.description} />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </React.Fragment>
            ))}
          </List>
          </>
        )}
      </div>
    </MuiPickersUtilsProvider>
  );
}
